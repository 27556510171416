<template>
    <div class="card" style="margin: 10px;">
      <div class="card-content">
        <vue-apex-charts ref="lineChart" type="line" height="300" :options="chartOptions" :series="users"></vue-apex-charts>
      </div>
    </div>
  </template>
  
  <script>
  import VueApexCharts from "vue-apexcharts";
  import NetworkManager from "@/network";
  
  export default {
    name: "AppRegistationLineChart",
    components: {VueApexCharts},
    data() {
      return {
  
        users: [
        ],
        chartOptions: {
          chart: {
            height: 250,
            type: 'line',
            zoom: {
              enabled: false
            }
          },
          // dataLabels: {
          //   enabled: false
          // },
          stroke: {
            curve: 'straight'
          },
          title: {
            text: 'App Users',
            align: 'left'
          },
          grid: {
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            },
          },
          xaxis: {
            categories: [],
          },
        yaxis: {
          forceNiceScale: true,
          labels: {
            formatter: function (val) {
              return parseInt(val).toString();
            }
          }
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return parseInt(val).toString();
            }
          }
        }
        },
      }
    },
  
    methods: {
      loadData: function (){
        const self = this
  
        NetworkManager.apiRequest('api/dashboard/getTotalAppUsersByMonth', {}, function (e){
          if(e.statusCode === 200){
            self.users = e.data.users
            self.$refs.lineChart.updateOptions({
              xaxis: {
                categories: e.data.monthNames,
              }
            })
          }
          else {
            //
          }
        })
      }
    },
  
    mounted() {
      this.loadData()
    }
  }
  </script>
  
  <style scoped>
  
  </style>
  