<template>
  <section>
    <div class="card-header p-3">
      <div class="columns" style="width: 100%">
        <div class="column">
          <b-breadcrumb
              align="is-left"
          >
            <b-breadcrumb-item tag='router-link' to="/newsfeed" >News Feed</b-breadcrumb-item>
            <b-breadcrumb-item tag='router-link' to="" active >Edit</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
        <div class="column">
        </div>
      </div>
    </div>

    <div class="card-content" style="overflow: auto; height: 65vh">
      <section>
        <b-field
          horizontal
          :type="NewsFeedData.title.class"
          :message="NewsFeedData.title.message">
        <template slot="label">Title <span class="has-text-danger">*</span></template>
        <b-input @blur="NewsFeedData.title.blur()" v-model="NewsFeedData.title.value" maxlength="250" readonly expanded></b-input>
      </b-field>
      <!-- <b-field
            horizontal
            :type="NewsFeedData.valid_from.class"
            :message="NewsFeedData.valid_from.message">
          <template slot="label">Valid From <span class="has-text-danger">*</span></template>
          <b-datepicker
              v-model="NewsFeedData.valid_from.value"
              @input="onFromDateChange()"
              :show-week-number="false"
              locale="en-CA"
              placeholder="Click to select..."
              icon="calendar-today"
              trap-focus>
          </b-datepicker>
        </b-field> -->

        <!-- <b-field
            horizontal
            :type="NewsFeedData.valid_to.class"
            :message="NewsFeedData.valid_to.message">
          <template slot="label">Valid To <span class="has-text-danger">*</span></template>
          <b-datepicker
              v-model="NewsFeedData.valid_to.value"
              :min-date="NewsFeedData.valid_from.value"
              :show-week-number="false"
              locale="en-CA"
              placeholder="Click to select..."
              icon="calendar-today"
              trap-focus>
          </b-datepicker>
        </b-field> -->
        <b-field
            horizontal
            :type="NewsFeedData.content.class"
            :message="NewsFeedData.content.message">
          <template slot="label">Content <span class="has-text-danger">*</span></template>
          <b-input @blur="NewsFeedData.content.blur()" v-model="NewsFeedData.content.value" type="textarea" maxlength="2500" readonly expanded></b-input>
        </b-field>

        <b-field horizontal
                 :type="NewsFeedData.image.class"
                 :message="NewsFeedData.image.message">
          <template slot="label">Image <span class="has-text-danger">*</span></template>
          <div class="">
            <div class="columns is-align-content-space-evenly is-flex-wrap-wrap" >
              <div class="column">
                <image-upload-component
                :type="NewsFeedData.image.class"
                v-model="NewsFeedData.image.value"
                :index="0"
                :image-height="imageHeight"
                :image-width="imageWidth"
                :image-validations="imageValidations"
                :placeholder_image="placeholder_image"
                @on-upload="onImageUpload" disabled
            ></image-upload-component>
              </div>
              <div class="column" style="text-align: center; height: 200px; width: 200px">
              </div>
            </div>
          </div>
        </b-field>

        <b-field
          horizontal
          message=" ">
        <template slot="label">Status <span class="has-text-danger">*</span></template>
        <div class="block">
          <b-radio v-model="NewsFeedData.disabled.value"

                   name="status"
                   native-value="0">
            Active
          </b-radio>
          <b-radio v-model="NewsFeedData.disabled.value"
                   name="status"
                   native-value="1"
                   type="is-danger">
                   Not Active
          </b-radio>

        </div>
      </b-field>

      </section>
    </div>

    <footer class="card-footer">
      <div class="columns" style="width: 100%">
        <div class="column">
          <p class="column mb-0 pb-1 has-text-danger is-italic">Fields marked with <big>*</big> are mandatory</p>
          <p class="column mt-0 pt-0 mb-0 pb-0 has-text-danger is-italic">Upload Image Size:</p>
          <p class="column mt-0 pt-0 has-text-danger is-italic">Width:800-900px , Height:400-450px </p>   
        </div>
        <div class="column">
          <div class="buttons is-pulled-right" style="margin: 5px">
            <b-button @click="validateAndSubmit()" :loading="false" type="is-success">Save</b-button>
            <b-button @click="$router.go(-1)"  type="is-danger">Cancel</b-button>
          </div>
        </div>
      </div>
    </footer>
  </section>
</template>
  
  <script>
import NetworkManager from "@/network"
import { FieldValidator } from "@/helpers/validators/fieldValidator"
import ImageUploadComponent, {ImageValidator} from "@/components/ImageUploadComponent"
import {appSettings} from "@/appSettings";


export default {
  name: "EditNotification",
  components: {ImageUploadComponent},
  data() {
    return {

      imageHeight: 200,
      imageWidth : 300,
      marginError: 15,
      image: null,
      imageValidations: new ImageValidator(450, 400, 900, 800, "Uploaded image is not valid"),
      placeholder_image: require('@/assets/image-placeholder.png'),
      newsId: 0,
      NewsFeedData: {
        title: new FieldValidator(/^(.*[a-zA-Z]){2,}[\w\s\S]*$/),
        content: new FieldValidator(/^(.*[a-zA-Z]){2,}[\w\s\S]*$/),
        valid_from: new FieldValidator(undefined, undefined, new Date(), true),
        valid_to: new FieldValidator(undefined, undefined, new Date(), true),
        image: new FieldValidator(undefined, "Please select a image", undefined, true),
        disabled: new FieldValidator()
      },
      checkBoxVal: false
    }
  },

  methods: {
    onImageUpload: function (data){
      this.bankData.logo.blur()
      if(!data.valid){
        this.bankData.logo.invalidate('Uploaded image is invalid')
      }
    },
    loadImage: function (path){
      return appSettings.$api_url + "/" + path + "?" + new Date().getTime()
    },
    onFromDateChange: function (){
      const self = this

      if(self.NewsFeedData.valid_to.value.getTime() < self.NewsFeedData.valid_from.value.getTime()){
        self.NewsFeedData.valid_to.value = self.NewsFeedData.valid_from.value
      }
    },
    getForEdit: function (){
      const self = this

      const data = {
        id : self.$route.params.id
      }

      self.showFullScreenLoader()

      NetworkManager.apiRequest('api/NewsFeed/get-for-edit', data, function (e){
        if(e.statusCode === 200){
          const data = e.data.news
          self.newsId = data.id
          self.NewsFeedData.title.value = data.title
          self.NewsFeedData.content.value = data.content
          self.NewsFeedData.valid_from.value = new Date(data.valid_from)
          self.NewsFeedData.valid_to.value = new Date(data.valid_to)
          self.NewsFeedData.image.value = data.image
          self.NewsFeedData.disabled.value = data.disabled

          self.placeholder_image = self.loadImage(data.image)

        }

        self.hideFullScreenLoader()
      })
    },

    validate: function (){
      const self = this
      let isValid = true

      Object.keys(self.NewsFeedData).forEach(function(key) {
        console.log(self.NewsFeedData[key].validate())
         if(!self.NewsFeedData[key].validate()) isValid = false
      })

      return isValid
    },

    validateAndSubmit: function (){
      const self = this

      let validFrom = self.formatDate(self.NewsFeedData.valid_from.value)
        let validTo = self.formatDate(self.NewsFeedData.valid_to.value)
        validFrom = validFrom.split(' ')[0] + 'T00:00:01'
        validTo = validTo.split(' ')[0] + 'T23:59:59'

      if(self.validate()){
      
        const data = {
          id: self.$route.params.id,
          title : self.NewsFeedData.title.value,
          content : self.NewsFeedData.content.value,
          valid_from : validFrom,
          valid_to : validTo,
          image : self.NewsFeedData.image.value,
          disabled : self.NewsFeedData.disabled.value
        }

        self.showFullScreenLoader()

        NetworkManager.apiRequest('api/NewsFeed/edit', data, function (e){
          if(e.statusCode === 200){
            const params = {
              type: 'is-success',
              message: 'News Feed sent for approval'
            }
            self.$buefy.toast.open(params)
            self.navigateTo('NewsFeed')
          }
          else {
            const params = {
              type: 'is-warning',
              message: 'News Feed save failed!.'
            }
            self.$buefy.toast.open(params)
          }

          self.hideFullScreenLoader()
        })
      }
    }
  },

  mounted() {
    this.NewsFeedData.disabled.value = 0
    this.getForEdit()
  }
}
</script>
  
  
  <style scoped>
  
  </style>
  