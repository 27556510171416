<template>
  <section>
    <div class="card-header p-3">
      <div class="columns" style="width: 100%">
        <div class="column">
          <b-breadcrumb align="is-left">
            <b-breadcrumb-item tag='router-link' to="" active>Notification</b-breadcrumb-item>
          </b-breadcrumb>
        </div>    
      </div>
    </div>
    <b-tabs v-model="activeTab">
      <b-tab-item label="General">
        <div class="columns">
          <div class="column is-three-fifths"></div>
          <div class="column">
          <b-field>
            <b-input size="is-small" v-model="globalSearch" icon="magnify" placeholder="Search"></b-input>
          </b-field>
        </div>
        <div class="column is-narrow">
          <b-button v-if="hasPermission('notification-create')" type="is-success is-small" class="is-pulled-right"
            icon-right="plus" @click="navigateTo('CreateNotification')">Create Notification+</b-button>
        </div>
        </div>
          <change-request v-if="hasPermission('notification-approve')" :data="crData" :filter-out="filterOutFromCR"
            :is-loading="crIsLoading" @approve-or-reject="approve_or_reject" />

          <b-table :height="vhToPixels(62)" :data="filteredNotifications" :paginated="tableOptions.isPaginated"
            :per-page="tableOptions.perPage" :current-page.sync="tableOptions.currentPage"
            :pagination-simple="tableOptions.isPaginationSimple" :pagination-position="tableOptions.paginationPosition"
            :default-sort-direction="tableOptions.defaultSortDirection"
            :pagination-rounded="tableOptions.isPaginationRounded" :sort-icon="tableOptions.sortIcon"
            :sort-icon-size="tableOptions.sortIconSize" default-sort="id" aria-next-label="Next page"
            aria-previous-label="Previous page" aria-page-label="Page" aria-current-label="Current page"
            :sticky-header="tableOptions.stickyHeaders" :page-input="tableOptions.hasInput"
            :pagination-order="tableOptions.paginationOrder" :page-input-position="tableOptions.inputPosition"
            :debounce-page-input="tableOptions.inputDebounce">

            <b-table-column field="id" label="ID" width="40" sortable numeric v-slot="props">
              {{ props.row.id }}
            </b-table-column>

            <b-table-column field="type" label="Type" sortable v-slot="props">
              <div style="max-width: 200px; word-wrap: break-word; white-space: normal;">
                {{ props.row.type }}
              </div>
            </b-table-column>

            <b-table-column field="title" label="Title" sortable v-slot="props">
              <div style="max-width: 200px; word-wrap: break-word; white-space: normal;">
                {{ props.row.title }}
              </div>
            </b-table-column>

            <b-table-column field="message" label="Message" sortable v-slot="props">
              <div style="max-width: 600px; word-wrap: break-word; white-space: normal;">
                {{ props.row.message }}
              </div>
            </b-table-column>

            <!-- <b-table-column field="countries" label="Countries" sortable v-slot="props">
          <b-taglist >
            <b-tag v-for="country in props.row.countries" :key="country.id" type="is-primary">{{country}}</b-tag>
          </b-taglist>
        </b-table-column> -->

            <!-- <b-table-column field="branch" label="Image" v-slot="props">
          <div>
            <img :src="getImageUrl(props.row.image)" style="height: 40px">
          </div>
        </b-table-column> -->

            <!-- <b-table-column field="disabled" label="Status" sortable v-slot="props">
          <b-tag :type="(props.row.disabled === 0 ? 'is-success' : 'is-danger') + ' is-light'">{{ props.row.disabled === 0
            ? 'Active' : 'Inactive' }}</b-tag>
        </b-table-column> -->

            <b-table-column field="created_at" label="Created At" sortable centered v-slot="props">
              <span class="tag is-success">
                {{ new Date(props.row.created_at).toLocaleString() }}
              </span>
            </b-table-column>

            <!-- <b-table-column field="updated_at" label="Updated At" sortable centered v-slot="props">
          <span class="tag is-success">
            {{ new Date(props.row.updated_at).toLocaleString() }}
          </span>
        </b-table-column> -->
            <b-table-column field="created_by" label="Created By" sortable v-slot="props">
              <div style="max-width: 200px; word-wrap: break-word; white-space: normal;">
                {{ props.row.created_by }}
              </div>
            </b-table-column>


            <!-- <b-table-column field="action" label="Action" v-slot="props">
          <div class="buttons" v-if="props.row.locked === 0">
            <b-tooltip v-if="hasPermission('notification-edit')" label="Edit">
              <b-button type="is-danger" size="is-small" @click="navigateTo('EditNotification', { id: props.row.id })"
                icon-left="lead-pencil"></b-button>
            </b-tooltip>

          </div>
          <b-tooltip v-else label="Locked">
            <b-icon icon="lock" size="is-medium">
            </b-icon>
          </b-tooltip>
        </b-table-column> -->

          </b-table>
      </b-tab-item>
      <b-tab-item label="Failed">
        <FailedNotificationList></FailedNotificationList>
      </b-tab-item>
    </b-tabs>
    <br />

  </section>
</template>

<script>
import ChangeRequest from "@/components/ChangeRequest"
import NetworkManager from "@/network"
import { FieldValidator } from "@/helpers/validators/fieldValidator"
import FailedNotificationList from "@/views/Notification/components/FailedNotificationList"
import { appSettings } from "@/appSettings"

export default {
  name: 'NotificationTable',
  components: {
    ChangeRequest,
    FailedNotificationList
  },
  data() {
    return {
      notifications: [],
      crData: [],
      crIsLoading: false,
      filterOutFromCR: ['id', 'created_at', 'updated_at', 'locked'],
      globalSearch: "",
      activeTab: 0,
      isPwChangeModalActive: false,
      isPwChangeModalLoading: false,

      selectedUsername: '',
      newPassword: new FieldValidator(),

      tableOptions: {
        isPaginated: true,
        isPaginationSimple: false,
        isPaginationRounded: false,
        paginationPosition: 'bottom',
        defaultSortDirection: 'asc',
        sortIcon: 'arrow-up',
        sortIconSize: 'is-small',
        currentPage: 1,
        perPage: 50,
        hasInput: false,
        paginationOrder: '',
        inputPosition: '',
        inputDebounce: '',
        stickyHeaders: true,
      }
    };
  },

  computed: {
    filteredNotifications() {
      return this.notifications.filter((notification) => {
        return (
          notification.type
            .toLowerCase()
            .startsWith(this.globalSearch.toLowerCase()) ||
          notification.title
            .toString()
            .toLowerCase()
            .startsWith(this.globalSearch.toLowerCase()) ||
          notification.created_by
            .toString()
            .toLowerCase()
            .startsWith(this.globalSearch.toLowerCase())
        );
      });
    },
  },

  methods: {
    getImageUrl: function (path) {
      return appSettings.$api_url + "/" + path + "?" + new Date().getTime()
    },
    onChangePasswordClick: function (username) {
      this.selectedUsername = username
      this.isPwChangeModalActive = true

      this.newPassword.value = ''
      this.newPassword.blur()
    },

    generatePassword: function () {
      const self = this

      self.newPassword.blur()

      self.newPassword.value = self.generateRandomPassword()
    },

    copyToClipboard: function () {
      const self = this

      const dummy = document.createElement("textarea");
      document.body.appendChild(dummy);
      dummy.value = self.newPassword.value
      dummy.select()
      document.execCommand("copy");
      document.body.removeChild(dummy);

      self.$buefy.toast.open({
        message: 'Copied to Clipboard',
      })

    },

    loadData: function () {
      const self = this

      self.showFullScreenLoader()
      NetworkManager.apiRequest('api/Notifications/list', {}, function (e) {
        if (e.statusCode === 200) {
          self.crData = e.data.changeRequests
          self.notifications = e.data.notifications
        }

        self.hideFullScreenLoader()
      })
    },


    approve_or_reject: function (data) {
      const self = this
      this.crIsLoading = true

      NetworkManager.apiRequest('api/Notifications/approve', data, function (e) {
        if (e.statusCode === 200) {
          const params = {
            type: 'is-success',
            message: 'Notification ' + data.actionTaken + ' successfully'
          }
          self.$buefy.toast.open(params)
          location.reload()
        }
        else {
          const params = {
            type: 'is-warning',
            message: 'Notification ' + data.actionTaken + ' failed!'
          }
          self.$buefy.toast.open(params)
        }

        self.crIsLoading = false
      })
    }

  },

  mounted() {
    this.newPassword.regex = this.regex.passwordValidation
    this.newPassword.errorMessage = "Password should contain minimum 8 characters maximum 32, at least two uppercase letters, two lowercase letters, one number and one special character (@#$!%*?&()[]{}-_+=|/\\:;\"<>?)"
    this.loadData()
  }
};
</script>

<style>
.is-sticky-column-one {
  background: #23d160 !important;
  color: white !important;
}

.is-sticky-column-two {
  background: #167df0 !important;
  color: white !important;
}
</style>