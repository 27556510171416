let appSettings

if (process.env.NODE_ENV === "production") {
    appSettings = {
        $api_url: "https://qa-apay.paymediasolutions.com:5002",
        // $api_url: "https://dev-apay.paymediasolutions.com:5000",
        // $api_url: "https://uat-apay.paymediasolutions.com:5001",
        // $api_url_internal: "https://uatlankaremitv2.lankapay.net:7016",
        timeoutDuration: 90000
    }
} else {
    appSettings = {
        $api_url: "https://qa-apay.paymediasolutions.com:5002",
        // $api_url: "https://uat-apay.paymediasolutions.com:5001",
        // $api_url: "https://dev-apay.paymediasolutions.com:5000",
        timeoutDuration: 60000
    }
}

appSettings.canAccessInternally = true
appSettings.internalWebPort = "7027"

appSettings.version = "1.0"
appSettings.year = "2023"

export { appSettings }
