<template>
  <div class="columns">
    <div class="column is-half has-text-right">
      <p class="comparison-header">{{ header }} :</p>
    </div>
    <div class="column is-half has-text-left">
      <div>
        <p>New Images</p>
        <div class="columns">
          <div class="column" v-for="item in newValue.value" :key="item.id">
            <a :href="getImageUrl(item)" target="_blank">
              <img type="is-primary is-light" style="max-height: 70px" :src="getImageUrl(item)" />
            </a>
          </div>
        </div>
      </div>
      <div v-if="(oldValue !== undefined && oldValue !== null && !isArrayEqual(oldValue.value, newValue.value))" class="old-value">
        <p>Old Images</p>
        <div class="columns">
          <div class="column" v-for="item in oldValue.value" :key="item.id">
            <a :href="getImageUrl(item)" target="_blank">
              <img type="is-primary is-light" style="max-height: 70px" :src="getImageUrl(item)" />
            </a>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { appSettings } from "../appSettings"

export default {
  name: "ChangeRequestImageArrayComparisonItem",
  props: ['header', 'newValue', 'oldValue'],
  data() {
    return {

    }
  },
  methods: {
    isArrayEqual(arr1, arr2) {
      if (arr1.length !== arr2.length) return false;
      for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) return false;
      }
      return true;
    },
    getImageUrl(path) {
      return appSettings.$api_url + "/" + path + "?" + new Date().getTime()
    }
  }
}
</script>

<style scoped>
.comparison-header {
  font-weight: bold;
}

.old-value {
  color: #ff4d4d;
  text-decoration: line-through;
}
</style>
