<template>
  <section>
    <div class="card-header p-3">
      <div class="columns" style="width: 100%">
        <div class="column">
          <b-breadcrumb align="is-left">
            <b-breadcrumb-item tag='router-link' to="" active>KYC User Information</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
        <div class="column">
        </div>
      </div>
    </div>
    <div class="card-content" style="overflow: auto; height: 83vh">
      <div class="columns" style="margin-top: 10px">
        <b-field class="column is-one-fifths is-small" label="UID/Email/Contact Number" label-position="on-border">
          <b-input v-model="searchParams.data" @blur="searchParams.data = searchParams.data.trim()" maxlength="30"
            size="is-small"></b-input>
        </b-field>
        <b-field class="column is-small" label="KYC Status" label-position="on-border">
          <b-select v-model="searchParams.kycStatus" placeholder="Select a type" size="is-small" expanded>
            <option :value="'PENDING'" selected>Pending</option>
            <option :value="'APPROVED'">Approved</option>
            <option :value="''">All</option>
          </b-select>
        </b-field>
        <b-field class="column is-one-fifths is-small" label="Fetch Count" label-position="on-border">
          <b-select v-model="searchParams.fetchCount" placeholder="Select a Count" size="is-small" expanded>
            <option :value="10" selected>10</option>
            <option :value="25">25</option>
            <option :value="50">50</option>
            <option :value="100">100</option>
            <option :value="99999999">All</option>
          </b-select>
        </b-field>
        <b-field class="column" label="">
          <div class="buttons">
            <b-button size="is-small" type="is-success" @click="loadData">Search</b-button>
          </div>
        </b-field>
      </div>

      <b-table :height="vhToPixels(50)" :data="KYCUsers" :paginated="tableOptions.isPaginated"
        :per-page="tableOptions.perPage" :current-page.sync="tableOptions.currentPage"
        :pagination-simple="tableOptions.isPaginationSimple" :pagination-position="tableOptions.paginationPosition"
        :default-sort-direction="tableOptions.defaultSortDirection" :pagination-rounded="tableOptions.isPaginationRounded"
        :sort-icon="tableOptions.sortIcon" :sort-icon-size="tableOptions.sortIconSize" default-sort="id"
        aria-next-label="Next page" aria-previous-label="Previous page" aria-page-label="Page"
        aria-current-label="Current page" :sticky-header="tableOptions.stickyHeaders" :page-input="tableOptions.hasInput"
        :pagination-order="tableOptions.paginationOrder" :page-input-position="tableOptions.inputPosition"
        :debounce-page-input="tableOptions.inputDebounce">

        <b-table-column field="id" label="ID" width="40" sortable numeric v-slot="props" sticky>
          {{ props.row.kycId }}
        </b-table-column>

        <b-table-column field="firstName" label="First Name" sortable v-slot="props" width="200">
          {{ props.row.first_name }}
        </b-table-column>

        <b-table-column field="lastName" label="Last Name" sortable v-slot="props" width="200">
          {{ props.row.last_name }}
        </b-table-column>

        <b-table-column field="uidType" label="UID Type" sortable v-slot="props" width="200">
          {{ props.row.uid_type }}
        </b-table-column>

        <b-table-column field="uid" label="UID" sortable v-slot="props" width="200">
          {{ props.row.uid }}
        </b-table-column>

        <b-table-column field="contact" label="Contact Number" sortable v-slot="props" width="200">
          {{ props.row.phone_no }}
        </b-table-column>

        <b-table-column field="email" label="Email" sortable v-slot="props" width="200">
          {{ props.row.email }}
        </b-table-column>

        <b-table-column field="disabled" label="KYC Status" sortable v-slot="props">
          <b-tag :type="getKycStatusClass(props.row.status)" class="is-light">
            {{ props.row.status }}
          </b-tag>
        </b-table-column>

        <b-table-column field="created_at" label="Created At" sortable centered v-slot="props">
          <span class="tag is-success">
            {{ new Date(props.row.created_at).toLocaleString() }}
          </span>
        </b-table-column>

        <b-table-column field="updated_at" label="Updated At" sortable centered v-slot="props">
          <span class="tag is-success">
            {{ new Date(props.row.updated_at).toLocaleString() }}
          </span>
        </b-table-column>

        <b-table-column label="Actions" v-slot="props" width="200">
          <b-button @click="openModal(props.row)" size="is-small" type="is-warning">Review</b-button>
        </b-table-column>

      </b-table>
    </div>
    <br />
    <b-modal v-model="isKycModalActive" title="KYC Information" width="800px" :can-cancel="false" full-screen>
      <template #default>
        <section class="modal-card-body p-0">
          <header class="modal-card-head">
            <p class="modal-card-title">KYC Information <b-tag>{{ KYCUser.uid }}</b-tag> <b-tag style="margin-left: 10px"
                :type="getKycStatusClass(KYCUser.status)" class="is-light"> {{
                  KYCUser.status }}</b-tag></p>
            <button
                type="button"
                class="delete"
                @click="cancelKYC"/>
          </header>
          <section class="modal-card-body">
            <div class="columns">
              <div class="column is-half" v-if="submittedData && KYCUser.created_at.slice(0, 16) !== KYCUser.updated_at.slice(0, 16)" style="padding: 0 20px;">
                <br><br>
                <p class="subtitle">Personal Details</p>
                <div class="columns">
                  <div class="column is-three-fifths">
                    <div class="field-and-switch"
                      v-for="(field, key) in Object.assign({}, ...Object.keys(submittedData).slice(0, -14).map(key => ({ [key]: submittedData[key] })))"
                      :key="key">
                      <b-field :label="toTitleCase(field.title)" :label-position="labelPosition" class="mt-2 mb-2">
                        <b-input :value="field.value" readonly></b-input>
                      </b-field>
                    </div>
                    <div class="columns mt-0 mb-0">
                      <div
                        v-for="(field, key, index) in Object.assign({}, ...Object.keys(submittedData).slice(2, -12).map(key => ({ [key]: submittedData[key] })))"
                        :key="key" class="column" :class="{ 'is-one-quarter': index === 0, '': index !== 0 }"
                        :style="{ width: index === 0 ? '120px' : 'auto' }">
                        <b-field :label="toTitleCase(field.title)" :label-position="labelPosition">
                          <b-input :value="field.value" readonly></b-input>
                        </b-field>
                      </div>
                    </div>
                    <div class="field-and-switch"
                      v-for="(field, key) in Object.assign({}, ...Object.keys(submittedData).slice(4, -4).map(key => ({ [key]: submittedData[key] })))"
                      :key="key">
                      <b-field :label="toTitleCase(field.title)" :label-position="labelPosition" class="mt-2 mb-2">
                        <b-input :value="field.value" readonly></b-input>
                      </b-field>
                    </div>
                  </div>
                  <!-- <div class="column switches-column">
                  <div class="field-and-switch"
                    v-for="(field, key) in Object.assign({}, ...Object.keys(submittedData).slice(0, -14).map(key => ({ [key]: submittedData[key] })))"
                    :key="key">
                    <b-switch v-model="field.approved" :type="field.approved ? 'is-success' : 'is-danger'" class="m-4"
                      disabled>
                      <span style="font-weight: bold" :class="field.approved ? 'has-text-success' : 'has-text-danger'">
                        {{ field.approved ? 'Approve' : 'Reject' }}
                      </span>
                    </b-switch>
                  </div>
                </div> -->
                  <div class="column switches-column">
                    <div class="field-and-switch"
                      v-for="(field, key) in Object.assign({}, ...Object.keys(submittedData).slice(0, -14).map(key => ({ [key]: submittedData[key] })))"
                      :key="key">
                      <b-switch v-model="field.approved" :type="field.approved ? 'is-success' : 'is-danger'" class="m-4"
                        disabled>
                        <span style="font-weight: bold" :class="field.approved ? 'has-text-success' : 'has-text-danger'">
                          {{ field.approved ? 'Approve' : 'Reject' }}
                        </span>
                      </b-switch>
                    </div>
                    <div class="field-and-switch">
                      <b-switch v-model="submittedContactApprove"
                        :type="submittedContactApprove ? 'is-success' : 'is-danger'" class="m-4" disabled>
                        <span style="font-weight: bold"
                          :class="submittedContactApprove ? 'has-text-success' : 'has-text-danger'">
                          {{ submittedContactApprove ? 'Approve' : 'Reject' }}
                        </span>
                      </b-switch>
                    </div>
                    <div class="field-and-switch"
                      v-for="(field, key) in Object.assign({}, ...Object.keys(submittedData).slice(4, -4).map(key => ({ [key]: submittedData[key] })))"
                      :key="key">
                      <b-switch v-model="field.approved" :type="field.approved ? 'is-success' : 'is-danger'" class="m-4"
                        disabled>
                        <span style="font-weight: bold" :class="field.approved ? 'has-text-success' : 'has-text-danger'">
                          {{ field.approved ? 'Approve' : 'Reject' }}
                        </span>
                      </b-switch>
                    </div>
                  </div>
                </div>
                <hr class="mb-4 mt-0" style="background-color: #9c9c9c;border: none;height: 0.5px;">
                <h4 class="subtitle">Personal Documents</h4><br>
                <div class="columns mb-0">
                  <b-field class="column" label="Passport/Other Document" :label-position="labelPosition">
                    <img
                      :src="submittedData.image1.value ? getImageUrl(submittedData.image1.value) : require('@/assets/image-placeholder.png')"
                      style="border: 1px solid blue; width: 400px;">
                  </b-field>
                  <b-field class="column" label="Other Document" :label-position="labelPosition">
                    <img
                      :src="submittedData.image2.value ? getImageUrl(submittedData.image2.value) : require('@/assets/image-placeholder.png')"
                      style="border: 1px solid blue; width: 400px;">
                  </b-field>
                </div>
                <div class="columns">
                  <div class="column">
                    <b-switch v-model="submittedData.image1.approved"
                      :type="submittedData.image1.approved ? 'is-success' : 'is-danger'" disabled>
                      <span style="font-weight: bold"
                        :class="submittedData.image1.approved === true ? 'has-text-success' : 'has-text-danger'">{{
                          submittedData.image1.approved === true ? 'Approve' : 'Reject' }}</span>
                    </b-switch>
                  </div>
                  <div class="column">
                    <b-switch v-model="submittedData.image2.approved.rejected"
                      :type="submittedData.image2.approved === true ? 'is-success' : 'is-danger'" disabled>
                      <span style="font-weight: bold"
                        :class="submittedData.image2.approved === true ? 'has-text-success' : 'has-text-danger'">{{
                          submittedData.image2.approved === true ? 'Approve' : 'Reject' }}</span>
                    </b-switch>
                  </div>
                </div>
                <div class="columns mt-4">
                  <b-field class="column" label="Other Document" :label-position="labelPosition">
                    <img
                      :src="submittedData.image3.value ? getImageUrl(submittedData.image3.value) : require('@/assets/image-placeholder.png')"
                      style="border: 1px solid blue; width: 400px;">
                  </b-field>
                  <b-field class="column" label="Selfie" :label-position="labelPosition">
                    <img
                      :src="submittedData.userImage.value ? getImageUrl(submittedData.userImage.value) : require('@/assets/image-placeholder.png')"
                      style="border: 1px solid blue; width: 400px;">
                  </b-field>
                </div>
                <div class="columns">
                  <div class="column">
                    <b-switch v-model="submittedData.image3.approved"
                      :type="submittedData.image3.approved ? 'is-success' : 'is-danger'" disabled>
                      <span style="font-weight: bold"
                        :class="submittedData.image3.approved === true ? 'has-text-success' : 'has-text-danger'">{{
                          submittedData.image3.approved === true ? 'Approve' : 'Reject' }}</span>
                    </b-switch>
                  </div>
                  <div class="column">
                    <b-switch v-model="submittedData.userImage.approved"
                      :type="submittedData.userImage.approved === true ? 'is-success' : 'is-danger'" disabled>
                      <span style="font-weight: bold"
                        :class="submittedData.userImage.approved === true ? 'has-text-success' : 'has-text-danger'">{{
                          submittedData.userImage.approved === true ? 'Approve' : 'Reject' }}</span>
                    </b-switch>
                  </div>
                </div>
              </div>
              <div v-if="submittedData && KYCUser.created_at.slice(0, 16) !== KYCUser.updated_at.slice(0, 16)"
              class="with-vertical-line" v-show="submittedData"></div>
              <div class="column" style="padding: 0 20px;">
                <p class="subtitle is-5" style="color:rgba(0, 0, 0, 0.349)">Submitted Information</p>
                <p class="subtitle">Personal Details</p>
                <!-- submitted fields -->
                <div class="columns">
                  <div class="column is-three-fifths">
                    <div class="field-and-switch"
                      v-for="(field, key) in Object.assign({}, ...Object.keys(currentData).slice(0, -14).map(key => ({ [key]: currentData[key] })))"
                      :key="key">
                      <b-field :label="toTitleCase(field.title)" :label-position="labelPosition" class="mt-2 mb-2">
                        <b-input :value="field.value" readonly></b-input>
                      </b-field>
                    </div>
                    <div class="columns mt-0 mb-0">
                      <div
                        v-for="(field, key, index) in Object.assign({}, ...Object.keys(currentData).slice(2, -12).map(key => ({ [key]: currentData[key] })))"
                        :key="key" class="column" :class="{ 'is-one-quarter': index === 0, '': index !== 0 }"
                        :style="{ width: index === 0 ? '120px' : 'auto' }">
                        <b-field :label="toTitleCase(field.title)" :label-position="labelPosition">
                          <b-input :value="field.value" readonly></b-input>
                        </b-field>
                      </div>
                    </div>
                    <div class="field-and-switch"
                      v-for="(field, key) in Object.assign({}, ...Object.keys(currentData).slice(4, -4).map(key => ({ [key]: currentData[key] })))"
                      :key="key">
                      <b-field :label="toTitleCase(field.title)" :label-position="labelPosition" class="mt-2 mb-2">
                        <b-input :value="field.value" readonly></b-input>
                      </b-field>
                    </div>
                  </div>
                  <div class="column switches-column">
                    <div class="field-and-switch"
                      v-for="(field, key) in Object.assign({}, ...Object.keys(currentData).slice(0, -14).map(key => ({ [key]: currentData[key] })))"
                      :key="key">
                      <b-switch v-model="field.approved" :type="field.approved ? 'is-success' : 'is-danger'" class="m-4">
                        <span style="font-weight: bold" :class="field.approved ? 'has-text-success' : 'has-text-danger'">
                          {{ field.approved ? 'Approve' : 'Reject' }}
                        </span>
                      </b-switch>
                    </div>
                    <div class="field-and-switch">
                      <b-switch v-model="contactApprove" :type="contactApprove ? 'is-success' : 'is-danger'" class="m-4"
                        @input="toggleContactApprove(currentData)">
                        <span style="font-weight: bold" :class="contactApprove ? 'has-text-success' : 'has-text-danger'">
                          {{ contactApprove ? 'Approve' : 'Reject' }}
                        </span>
                      </b-switch>
                    </div>
                    <div class="field-and-switch"
                      v-for="(field, key) in Object.assign({}, ...Object.keys(currentData).slice(4, -10).map(key => ({ [key]: currentData[key] })))"
                      :key="key">
                      <b-switch v-model="collectiveApprovalStatus" :type="collectiveApprovalStatus ? 'is-success' : 'is-danger'" class="m-4" @input="updateRelatedApprovalStatus">
                        <span style="font-weight: bold" :class="collectiveApprovalStatus ? 'has-text-success' : 'has-text-danger'">
                          {{ collectiveApprovalStatus ? 'Approve' : 'Reject' }}
                        </span>
                      </b-switch>
                    </div>
                    <div class="field-and-switch"
                      v-for="(field, key) in Object.assign({}, ...Object.keys(currentData).slice(6, -8).map(key => ({ [key]: currentData[key] })))"
                      :key="key">
                      <b-switch v-model="field.approved" :type="field.approved ? 'is-success' : 'is-danger'" class="m-4">
                        <span style="font-weight: bold" :class="field.approved ? 'has-text-success' : 'has-text-danger'">
                          {{ field.approved ? 'Approve' : 'Reject' }}
                        </span>
                      </b-switch>
                    </div>
                    <div class="field-and-switch"
                      v-for="(field, key) in Object.assign({}, ...Object.keys(currentData).slice(8, -4).map(key => ({ [key]: currentData[key] })))"
                      :key="key">
                      <b-switch v-model="collectiveAddressApprovalStatus" :type="collectiveAddressApprovalStatus ? 'is-success' : 'is-danger'" class="m-4" @input="updateRelatedAddressApprovalStatus">
                        <span style="font-weight: bold" :class="collectiveAddressApprovalStatus ? 'has-text-success' : 'has-text-danger'">
                          {{ collectiveAddressApprovalStatus ? 'Approve' : 'Reject' }}
                        </span>
                      </b-switch>
                    </div>
                  </div>
                </div>
                <hr class="mb-4 mt-0" style="background-color: #9c9c9c;border: none;height: 0.5px;">
                <h4 class="subtitle">Personal Documents</h4><br>
                <div class="columns mb-0">
                  <b-field class="column" label="Passport/Other Document" :label-position="labelPosition">
                    <img
                      :src="currentData.image1.value ? getImageUrl(currentData.image1.value) : require('@/assets/image-placeholder.png')"
                      style="border: 1px solid blue; width: 400px;">
                  </b-field>
                  <b-field class="column" label="Other Document" :label-position="labelPosition">
                    <img
                      :src="currentData.image2.value ? getImageUrl(currentData.image2.value) : require('@/assets/image-placeholder.png')"
                      style="border: 1px solid blue; width: 400px;">
                  </b-field>
                </div>
                <div class="columns">
                  <div class="column">
                    <b-switch v-model="collectiveApprovalStatus"
                      :type="collectiveApprovalStatus ? 'is-success' : 'is-danger'" @input="updateRelatedApprovalStatus">
                      <span style="font-weight: bold"
                        :class="collectiveApprovalStatus === true ? 'has-text-success' : 'has-text-danger'">{{
                          collectiveApprovalStatus === true ? 'Approve' : 'Reject' }}</span>
                    </b-switch>
                  </div>
                  <div class="column">
                    <b-switch v-model="collectiveApprovalStatus"
                      :type="collectiveApprovalStatus === true ? 'is-success' : 'is-danger'" @input="updateRelatedApprovalStatus">
                      <span style="font-weight: bold"
                        :class="collectiveApprovalStatus === true ? 'has-text-success' : 'has-text-danger'">{{
                          collectiveApprovalStatus === true ? 'Approve' : 'Reject' }}</span>
                    </b-switch>
                  </div>
                </div>
                <div class="columns mt-4">
                  <b-field class="column" label="Other Document" :label-position="labelPosition">
                    <img
                      :src="currentData.image3.value ? getImageUrl(currentData.image3.value) : require('@/assets/image-placeholder.png')"
                      style="border: 1px solid blue; width: 400px;">
                  </b-field>
                  <b-field class="column" label="Selfie" :label-position="labelPosition">
                    <img
                      :src="currentData.userImage.value ? getImageUrl(currentData.userImage.value) : require('@/assets/image-placeholder.png')"
                      style="border: 1px solid blue; width: 400px;">
                  </b-field>
                </div>
                <div class="columns">
                  <div class="column">
                    <b-switch v-model="collectiveApprovalStatus"
                      :type="collectiveApprovalStatus === true ? 'is-success' : 'is-danger'" @input="updateRelatedApprovalStatus">
                      <span style="font-weight: bold"
                        :class="collectiveApprovalStatus === true ? 'has-text-success' : 'has-text-danger'">{{
                          collectiveApprovalStatus === true ? 'Approve' : 'Reject' }}</span>
                    </b-switch>
                  </div>
                  <div class="column">
                    <b-switch v-model="currentData.userImage.approved"
                      :type="currentData.userImage.approved === true ? 'is-success' : 'is-danger'">
                      <span style="font-weight: bold"
                        :class="currentData.userImage.approved === true ? 'has-text-success' : 'has-text-danger'">{{
                          currentData.userImage.approved === true ? 'Approve' : 'Reject' }}</span>
                    </b-switch>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <footer class="modal-card-foot" style="display: flex; justify-content: flex-end;">
          <!-- <b-button type="is-success" :disabled="!canApproveKyc"
            @click="approveKyc = true">Approve</b-button>
          <b-button type="is-danger" :disabled="canApproveKyc"
            @click="rejectKyc = true">Reject</b-button> -->
          <b-button @click="cancelKYC()">Cancel</b-button>
          <b-button type="is-success" :loading="buttonsLoading" v-if="canApproveKyc"
            @click="approveKyc = true">Approve</b-button>
          <b-button type="is-danger" :loading="buttonsLoading" v-if="!canApproveKyc"
            @click="rejectKyc = true">Reject</b-button>
        </footer>
        </section>
      </template>
    </b-modal>
    <!-- approve modal -->
    <b-modal v-model="approveKyc" has-modal-card trap-focus :destroy-on-hide="false" aria-role="dialog"
      close-button-aria-label="Close" aria-modal>
      <div class="modal-card" style="width: 500px; height: 400px;">
        <header class="modal-card-head is-flex is-justify-content-center">
          <p class="title is-4 has-text-centered">Approve the KYC Information!</p>
        </header>
        <section class="modal-card-body has-text-centered">
          <div class="is-flex is-justify-content-center is-align-items-center mb-4">
            <img src="../../assets/Group 1000002633.png" alt="KYC" class="image is-128x128">
          </div>
          <p>Do you want</p>
          <p>to approve the KYC information?</p>
        </section>
        <footer class="modal-card-foot" style="display: flex; justify-content: flex-end;">
          <b-button v-if="submittedData === null" type="is-success"
            @click="createRequest(KYCUser.kycId, currentData)">Approve</b-button>
          <b-button v-if="submittedData !== null" type="is-success"
            @click="updateRequest(KYCUser.kycId, currentData)">Approve</b-button>
          <b-button @click="approveKyc = false">Cancel</b-button>
        </footer>
      </div>
    </b-modal>
    <!-- reject modal -->
    <b-modal v-model="rejectKyc" has-modal-card trap-focus :destroy-on-hide="false" aria-role="dialog"
      close-button-aria-label="Close" aria-modal>
      <div class="modal-card" style="width: 500px; height: 400px;">
        <header class="modal-card-head is-flex is-justify-content-center">
          <p class="title is-4 has-text-centered">Reject the KYC Information!</p>
        </header>
        <section class="modal-card-body has-text-centered">
          <div class="is-flex is-justify-content-center is-align-items-center mb-4">
            <img src="../../assets/Group 1000002634.png" alt="KYC" class="image is-128x128">
          </div>
          <p>Do you want</p>
          <p>to reject the KYC information?</p>
        </section>
        <footer class="modal-card-foot" style="display: flex; justify-content: flex-end;">
          <b-button v-if="submittedData === null" type="is-danger"
            @click="createRequest(KYCUser.kycId, currentData)">Reject</b-button>
          <b-button v-if="submittedData !== null" type="is-danger"
            @click="updateRequest(KYCUser.kycId, currentData)">Reject</b-button>
          <b-button @click="rejectKyc = false">Cancel</b-button>
        </footer>
      </div>
    </b-modal>
  </section>
</template>
  
<script>
import NetworkManager from "@/network"
// import ChangeRequest from "@/components/ChangeRequest";
import { appSettings } from "@/appSettings";
import { TransactionExportWizard } from "@/views/Reports/Supports";

export default {
  name: 'KYC',
  // components: {
  //   FullScreenLoader
  // },
  data() {
    return {
      approveKyc: false,
      rejectKyc: false,
      filterChanged: false,
      isKycModalActive: false,
      isLoading: false,
      currentData: {},
      searchParams: {
        data: '',
        kycStatus: '',
        fetchCount: 10,
      },
      submittedData: {},
      contactApprove: false,
      submittedContactApprove: false,
      KYCUsers: [],
      KYCUser: {},
      selectedAppUser: {},
      tableHeaders: [],

      exportWizard: new TransactionExportWizard("App Users"),
      labelPosition: 'on-border',
      tableOptions: {
        isPaginated: true,
        isPaginationSimple: false,
        isPaginationRounded: false,
        paginationPosition: 'bottom',
        defaultSortDirection: 'asc',
        sortIcon: 'arrow-up',
        sortIconSize: 'is-small',
        currentPage: 1,
        perPage: 10,
        hasInput: false,
        paginationOrder: '',
        inputPosition: '',
        inputDebounce: '',
        stickyHeaders: true,
      },
    }
  },

  computed: {
    canApproveKyc() {
      // Iterate through each field in currentData
      for (let key in this.currentData) {
        // Check if any field is rejected
        if (!this.currentData[key].approved) {
          // If any field is rejected, disable the approve button
          return false;
        }
      }
      // Enable the approve button if all fields are approved
      return true;
    },
    getKycStatusClass() {
      return (status) => {
        if (status === 'APPROVED') {
          return 'is-success';
        } else if (status === 'PENDING') {
          return 'is-warning';
        } else {
          return 'is-danger';
        }
      };
    },
    collectiveApprovalStatus() {
      // Check if any of the related fields are rejected
      if (
        !this.currentData.uidType.approved ||
        !this.currentData.uid.approved ||
        !this.currentData.image1.approved ||
        !this.currentData.image2.approved ||
        !this.currentData.image3.approved
      ) {
        return false; // If any field is rejected, set collective approval status to false
      }
      // If all fields are approved, set collective approval status to true
      return true;
    },
    collectiveAddressApprovalStatus() {
      // Check if any of the related fields are rejected
      if (
        !this.currentData.country.approved ||
        !this.currentData.city.approved ||
        !this.currentData.addressLine1.approved ||
        !this.currentData.addressLine2.approved
      ) {
        return false; // If any field is rejected, set collective approval status to false
      }
      // If all fields are approved, set collective approval status to true
      return true;
    }
  },

  watch: {
    contactApprove(newValue) {
      console.log('contactApprove changed:', newValue);
    },
    searchParams: {
      handler() {
        this.filterChanged = true
      },
      deep: true
    }
  },

  methods: {
    updateRelatedApprovalStatus(approvalStatus) {
      // Update approval status of related fields
      this.currentData.uidType.approved = approvalStatus;
      this.currentData.uid.approved = approvalStatus;
      this.currentData.image1.approved = approvalStatus;
      this.currentData.image2.approved = approvalStatus;
      this.currentData.image3.approved = approvalStatus;
    },
    updateRelatedAddressApprovalStatus(approvalStatus) {
      // Update approval status of related address fields
      this.currentData.country.approved = approvalStatus;
      this.currentData.city.approved = approvalStatus;
      this.currentData.addressLine1.approved = approvalStatus;
      this.currentData.addressLine2.approved = approvalStatus;
    },
    toTitleCase(str) {
  return str.replace(/(^|[^A-Za-z])([a-z])/g, function(match, pre, word) {
    var cmb = pre + word.toUpperCase();
    return cmb.replace('_', ' ')
  });
},
cancelKYC() {
      // Clear the data or perform any necessary cleanup
      // this.clearData();

      // Close the modal
      this.isKycModalActive = false;
      //location.reload()
    },
    toggleContactApprove(currentData) {
      console.log('toggleContactApprove called. contactApprove:', this.contactApprove);
      // Update currentData.dialCode.approved and currentData.phoneNo.approved based on contactApprove
      this.$set(currentData.dialCode, 'approved', this.contactApprove);
      this.$set(currentData.phoneNo, 'approved', this.contactApprove);
    },

    getImageUrl: function (path) {
      console.log(appSettings.$api_url + path + "?" + new Date().getTime());
      return appSettings.$api_url + "/" + path + "?" + new Date().getTime()
    },
    formatDateTime(date) {
      const formattedDateTime = date.toLocaleString(undefined, {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric', // Add 'second' option
      }).replace('T', ' ');

      return formattedDateTime.slice(0, -7); // Remove last 4 characters (milliseconds)
    },

    showUserImages(user) {
      console.log(user)
      this.selectedAppUser = {}
      this.selectedAppUser = user
      this.isImageModalActive = true
    },

    loadData: function () {
      const self = this

      self.showFullScreenLoader()
      NetworkManager.apiRequest('api/kyc/get-all', self.searchParams, function (e) {
        if (e.statusCode === 200) {
          self.KYCUsers = e.data.kycList
        }

        self.hideFullScreenLoader()
      })
    },
    openModal(rowData) {
      this.KYCUser = rowData;
      this.currentData = rowData.currentData;
      this.submittedData = rowData.submittedData;

      // Set submittedContactApprove based on submittedData.dialCode.approved
      this.submittedContactApprove = rowData.submittedData && rowData.submittedData.dialCode ? rowData.submittedData.dialCode.approved : false;

      // Set contactApprove based on currentData.dialCode.approved
      this.contactApprove = rowData.currentData && rowData.currentData.dialCode ? rowData.currentData.dialCode.approved : false;

      this.isKycModalActive = true;
    },
    createRequest(userId, kycData) {
      const self = this
      this.isLoading = true
      const data = {
        userId: userId,
        kycData: kycData
      }

      self.showFullScreenLoader()
      NetworkManager.apiRequest('api/kyc/create-request', data, function (e) {
        if (e.statusCode === 200) {
          const params = {
            type: 'is-success',
            message: 'KYC request created'
          }
          self.$buefy.toast.open(params)
          location.reload()
        }
        else {
          const params = {
            type: 'is-warning',
            message: 'KYC request create failed!'
          }
          self.$buefy.toast.open(params)
        }
        self.hideFullScreenLoader()
        self.isLoading = false
        self.isKycModalActive = false
      })
    },
    updateRequest(kycId, kycData) {
      const self = this
      this.isLoading = true
      const data = {
        kycId: kycId,
        kycData: kycData
      }
      self.showFullScreenLoader()
      NetworkManager.apiRequest('api/kyc/update-status', data, function (e) {
        if (e.statusCode === 200) {
          const params = {
            type: 'is-success',
            message: 'KYC updated successfully'
          }
          self.$buefy.toast.open(params)
          location.reload()
        }
        else {
          const params = {
            type: 'is-warning',
            message: 'KYC update failed!'
          }
          self.$buefy.toast.open(params)
        }
        self.hideFullScreenLoader()
        self.isLoading = false
        self.isKycModalActive = false
      })
    },
  },
  mounted() {
    // console.log('toggleContactApprove called. contactApprove:', this.contactApprove);
    // this.contactApprove = false;
    this.loadData()
  }
}
</script>
<style scoped>
.custom-modal-body,
.custom-modal,
.custom-modal .modal-card-body {
  overflow: hidden !important;
}

.with-vertical-line {
  border-right: 2px solid #ccc;
  margin: 20px 0;
}

.field-and-switch {
  display: flex;
  flex-direction: column;
}

.field-and-switch-inline {
  display: flex;
  flex-direction: row;
}</style>
  
  