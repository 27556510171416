<template>
  <b-navbar style="box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);"
  >
    <template #brand>
      <div>
        <b-navbar-item class="pl-10" tag="router-link" :to="{ path: '/dashboard' }">
          <img class="pl-5" src="../../assets/amana.png" style="height: 500px; width: auto;">

      </b-navbar-item>
      </div>
    </template>
    
    <template #end>
      <b-navbar-item tag="div">
        <b-icon icon="account-circle" size="is-medium"></b-icon>
        <b-navbar-dropdown :label="getUsername">
          <b-navbar-item @click="logout()">
            Logout
          </b-navbar-item>
        </b-navbar-dropdown>
      </b-navbar-item>
    </template>
  </b-navbar>
</template>

<script>
import JwtUtils from "@/helpers/jwtUtils"

export default {
  name: "NavBar",
  data() {
    return {
      user: null,
    }
  },

  computed: {
    getUsername(){
      return this.user !== null ? this.user.username : 'User'
    }
  },

  methods: {
    logout: function (){
      JwtUtils.logoutTheUser(true)
    }
  },

  mounted() {
    this.user = JwtUtils.loadToken().jwtObj
  }
}
</script>

<style scoped>
.navbar-item img {
    max-height: 2.75rem;
}
  .navbar-column {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Center the brand item */
.centered-item {
  text-align: center;
}
</style>
