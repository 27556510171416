<template>
    <div>
        <div class="columns" style="margin-top: 10px; margin-bottom: 0">
            <b-field class="column" label="">
                <div class="buttons column is-10 is-offset-2">
                    <b-button class="is-pulled-right mr-5" size="is-small" :disabled="filterChanged"
                        @click="generateXlsx" type="is-warning" icon-left="microsoft-excel">Download Transactions
                        List</b-button>
                </div>
            </b-field>

            


        </div>

        



        <b-table :height="vhToPixels(80)" :data="filteredList" :paginated="tableOptions.isPaginated"
            :per-page="tableOptions.perPage" :current-page.sync="tableOptions.currentPage"
            :pagination-simple="tableOptions.isPaginationSimple" :pagination-position="tableOptions.paginationPosition"
            :default-sort-direction="tableOptions.defaultSortDirection"
            :pagination-rounded="tableOptions.isPaginationRounded" :sort-icon="tableOptions.sortIcon"
            :sort-icon-size="tableOptions.sortIconSize" default-sort="id" aria-next-label="Next page"
            aria-previous-label="Previous page" aria-page-label="Page" aria-current-label="Current page"
            :sticky-header="tableOptions.stickyHeaders" :page-input="tableOptions.hasInput"
            :pagination-order="tableOptions.paginationOrder" :page-input-position="tableOptions.inputPosition"
            :debounce-page-input="tableOptions.inputDebounce">
            <b-table-column field="id" label="ID" width="40" sortable numeric v-slot="props" sticky>
                {{ props.row.id }}
            </b-table-column>

            <b-table-column field="tran_type" label="Transaction Type" sortable v-slot="props" width="200">
                {{ props.row.tran_type }}
            </b-table-column>

            <b-table-column field="NIC/Passport" label="NIC/Passport" sortable v-slot="props" width="200">
                {{ props.row.uid }}
            </b-table-column>

            <b-table-column field="trace" label="Trace Number" sortable v-slot="props" width="200">
                {{ props.row.trace }}
            </b-table-column>

            <b-table-column
              field="retrieval"
              label="Retrieval Number"
              sortable
              v-slot="props"
              width="200"
            >
              {{ props.row.retrieval }}
            </b-table-column>


            <b-table-column field="trace" label="Country" sortable v-slot="props" width="200">
                <div style="width: 100px">{{ props.row.country }}</div>
            </b-table-column>

            <b-table-column field="bank_name" label="Bank Name" sortable v-slot="props" width="200">
                <div style="width: 120px">{{ props.row.bank_name }}</div>
            </b-table-column>

            <b-table-column field="branch_name" label="Branch Name" sortable v-slot="props" width="200">
                <div style="width: 120px">{{ props.row.branch_name }}</div>
            </b-table-column>

            <b-table-column field="initiated_time" label="Tran Initiated Time" sortable v-slot="props">
                <span class="tag is-success expanded-col">{{
                    getDateTimeForDisplaying(props.row.initiated_time)
                }}</span>
            </b-table-column>

            <b-table-column field="beneficiary_data" label="Beneficiary Details" sortable v-slot="props">
                <b-collapse :open="false" aria-id="contentIdForA11y1">
                    <template #trigger="trigger">
                        <b-tooltip label="Click to view">
                            <b-button label="" icon-right="arrow-down-drop-circle-outline" size="is-small"
                                type="is-primary" aria-controls="contentIdForA11y1" :aria-expanded="trigger.open" />
                        </b-tooltip>
                    </template>
                    <div class="notification">
                        <div class="content" style="width: 20rem">
                            <h5>Beneficiary Details</h5>
                            <section class="card-content">
                                <div class="content pt-auto">
                                    <b-field label="Amount (LKR)" custom-class="is-small">
                                        <b-input :value="props.row.beneficiary_received_amount_lkr" size="is-small"
                                            readonly></b-input>
                                    </b-field>
                                    <b-field label="Bank Name" custom-class="is-small">
                                        <b-input :value="props.row.bank_name" size="is-small" readonly></b-input>
                                    </b-field>
                                    <b-field label="Branch Name" custom-class="is-small">
                                        <b-input :value="props.row.branch_name" size="is-small" readonly></b-input>
                                    </b-field>
                                    <b-field label="Account Number" custom-class="is-small">
                                        <b-input :value="props.row.account_number" size="is-small" readonly></b-input>
                                    </b-field>
                                    <b-field label="Beneficiary Name" custom-class="is-small">
                                        <b-input :value="props.row.beneficiary_name" size="is-small" readonly></b-input>
                                    </b-field>
                                    <b-field label="Currency Type" custom-class="is-small">
                                        <b-input :value="props.row.currency_code" size="is-small" readonly></b-input>
                                    </b-field>
                                    <b-field label="Account Type" custom-class="is-small">
                                        <b-input :value="props.row.account_currency" size="is-small" readonly></b-input>
                                    </b-field>
                                    <b-field v-if="props.row.proof_document" label="Proof Document"
                                        custom-class="is-small">
                                        <div>
                                            <img v-if="isImage(props.row.proof_document)"
                                                :src="getImageUrl(props.row.proof_document)" style="
                          border: 1px solid rgb(136, 136, 136);
                          width: 400px;
                        " />
                                            <div v-else>
                                                <img :src="require('@/assets/pdf-placeholder.png')" style="
                            border: 1px solid rgb(136, 136, 136);
                            width: 400px;
                          " />
                                                <p>{{ getFileName(props.row.proof_document) }}</p>
                                                <a style="color: blue; text-decoration: underline"
                                                    :href="getPdfUrl(props.row.proof_document)" target="_blank">Open
                                                    PDF</a>
                                            </div>
                                        </div>
                                    </b-field>
                                    <b-field label="Nick Name" custom-class="is-small">
                                        <b-input :value="props.row.nick_name" size="is-small" readonly></b-input>
                                    </b-field>
                                </div>
                            </section>
                        </div>
                    </div>
                </b-collapse>
            </b-table-column>

            <b-table-column field="account_number" label="Account Number" sortable v-slot="props" width="200">
                {{ props.row.account_number }}
            </b-table-column>

            <!-- <b-table-column field="account_currency" label="Account Currency" sortable v-slot="props" width="200">
                {{ props.row.account_currency }}
            </b-table-column>

            <b-table-column field="currency_code" label="Currency Code" sortable v-slot="props" width="200">
                {{ props.row.currency_code }}
            </b-table-column>

            <b-table-column field="amount" label="Amount" sortable v-slot="props" width="200">
                <p style="width: 100px">
                    {{
                    numberWithCommas(props.row.amount) + " " + props.row.currency_code
                }}
                </p>
            </b-table-column> -->

            <b-table-column
              field="account_currency"
              label="Account Currency"
              sortable
              v-slot="props"
              width="200"
            >
              {{ props.row.account_currency }}
            </b-table-column>

            <b-table-column
              field="currency_code"
              label="Currency Code"
              sortable
              v-slot="props"
              width="200"
            >
              {{ props.row.currency_code }}
            </b-table-column>

            <b-table-column
              field="amount"
              label="Amount"
              sortable
              v-slot="props"
              width="200"
            >
              <p style="width: 100px">
                {{
                  numberWithCommas(props.row.amount) +
                  " " +
                  props.row.currency_code
                }}
              </p>
            </b-table-column>

            <b-table-column
              field="ipg_charge"
              label="IPG Commission (USD)"
              sortable
              v-slot="props"
            >
              <p class="expanded-col">{{ props.row.ipg_commission }}</p>
            </b-table-column>

            <b-table-column
              field="Bank_charge"
              label="Bank Commission (USD)"
              sortable
              v-slot="props"
            >
              <p class="expanded-col">{{ props.row.bank_charge }}</p>
            </b-table-column>

            <b-table-column
              field="remitting_amount"
              label="Beneficiary Received Amount (USD)"
              sortable
              v-slot="props"
            >
              <p class="expanded-col">
                {{ props.row.beneficiary_received_amount_lkr }}
              </p>
            </b-table-column>

            <b-table-column
          field="ipg_data"
          label="IPG Details"
          sortable
          v-slot="props"
        >
          <b-collapse :open="false" aria-id="contentIdForA11y1">
            <template #trigger="trigger">
              <b-tooltip label="Click to view">
                <b-button
                  label=""
                  icon-right="arrow-down-drop-circle-outline"
                  size="is-small"
                  type="is-primary"
                  aria-controls="contentIdForA11y1"
                  :aria-expanded="trigger.open"
                />
              </b-tooltip>
            </template>
            <div class="notification">
              <div class="content" style="width: 20rem">
                <h5>IPG Deduction Details</h5>
                <section class="card-content">
                  <div class="content pt-auto">
                    <b-field
                      label="Exchange Rate (LKR)"
                      custom-class="is-small"
                    >
                      <b-input
                        :value="props.row.exchange_rate_final"
                        size="is-small"
                        readonly
                      ></b-input>
                    </b-field>
                    <b-field label="IPG Rate (%)" custom-class="is-small">
                      <b-input
                        :value="props.row.ipg_rate"
                        size="is-small"
                        readonly
                      ></b-input>
                    </b-field>
                    <b-field
                      :label="
                        'IPG Commission (' + props.row.currency_code + ')'
                      "
                      custom-class="is-small"
                    >
                      <b-input
                        :value="props.row.ipg_commission"
                        size="is-small"
                        readonly
                      ></b-input>
                    </b-field>
                    <b-field
                      :label="'Bank Charge (' + props.row.currency_code + ')'"
                      custom-class="is-small"
                    >
                      <b-input
                        :value="props.row.bank_charge"
                        size="is-small"
                        readonly
                      ></b-input>
                    </b-field>
                    <b-field
                      label="Refund Commission (%)"
                      custom-class="is-small"
                    >
                      <b-input
                        :value="props.row.refund_commission"
                        size="is-small"
                        readonly
                      ></b-input>
                    </b-field>
                    <b-field
                      label="Beneficiary Received Amount (USD)"
                      custom-class="is-small"
                    >
                      <b-input
                        :value="props.row.beneficiary_received_amount_lkr"
                        size="is-small"
                        readonly
                      ></b-input>
                    </b-field>
                  </div>
                </section>
              </div>
            </div>
          </b-collapse>
        </b-table-column>

            <b-table-column field="Purpose" label="Purpose" sortable v-slot="props" width="200">
                {{ props.row.purpose }}
            </b-table-column>

            <b-table-column field="purpose_description" label="Purpose Description" sortable v-slot="props" width="200">
                {{ props.row.purpose_description }}
            </b-table-column>

            <b-table-column field="card_payment_time" label="Card Payment Time" sortable v-slot="props">
                <span class="tag is-success expanded-col">{{
                    getDateTimeForDisplaying(props.row.card_payment_time)
                }}</span>
            </b-table-column>

            <b-table-column width="200" field="card_payment_status" label="Card Payment Status" sortable v-slot="props">
                <p class="expanded-col">{{ props.row.card_payment_status }}</p>
            </b-table-column>

            <b-table-column width="200" field="card_payment_description" label="Card Payment Description" sortable
                v-slot="props">
                <p class="expanded-col" style="width: 200px">
                    {{ props.row.card_payment_description }}
                </p>
            </b-table-column>

            <b-table-column field="ipg_transaction_id" label="IPG Transaction ID" sortable v-slot="props">
                <p class="expanded-col">{{ props.row.ipg_transaction_id }}</p>
            </b-table-column>

            <b-table-column field="ipg_data" label="IPG Details" sortable v-slot="props">
                <b-collapse :open="false" aria-id="contentIdForA11y1">
                    <template #trigger="trigger">
                        <b-tooltip label="Click to view">
                            <b-button label="" icon-right="arrow-down-drop-circle-outline" size="is-small"
                                type="is-primary" aria-controls="contentIdForA11y1" :aria-expanded="trigger.open" />
                        </b-tooltip>
                    </template>
                    <div class="notification">
                        <div class="content" style="width: 20rem">
                            <h5>IPG Deduction Details</h5>
                            <section class="card-content">
                                <div class="content pt-auto">
                                    <b-field label="Exchange Rate (LKR)" custom-class="is-small">
                                        <b-input :value="props.row.exchange_rate_final" size="is-small"
                                            readonly></b-input>
                                    </b-field>
                                    <b-field label="IPG Rate (%)" custom-class="is-small">
                                        <b-input :value="props.row.ipg_rate" size="is-small" readonly></b-input>
                                    </b-field>
                                    <b-field :label="'IPG Commission (' + props.row.currency_code + ')'"
                                        custom-class="is-small">
                                        <b-input :value="props.row.ipg_commission" size="is-small" readonly></b-input>
                                    </b-field>
                                    <b-field :label="'Bank Charge (' + props.row.currency_code + ')'"
                                        custom-class="is-small">
                                        <b-input :value="props.row.bank_charge" size="is-small" readonly></b-input>
                                    </b-field>
                                    <b-field label="Refund Commission (%)" custom-class="is-small">
                                        <b-input :value="props.row.refund_commission" size="is-small"
                                            readonly></b-input>
                                    </b-field>
                                    <b-field label="Beneficiary Received Amount (LKR)" custom-class="is-small">
                                        <b-input :value="numberWithCommas(
                    props.row.beneficiary_received_amount_lkr
                )
                    " size="is-small" readonly></b-input>
                                    </b-field>
                                </div>
                            </section>
                        </div>
                    </div>
                </b-collapse>
            </b-table-column>

            <b-table-column field="ceft_payment_time" label="CEFT Payment Time" sortable v-slot="props">
                <span class="tag is-success expanded-col">{{
                    getDateTimeForDisplaying(props.row.ceft_payment_time)
                }}</span>
            </b-table-column>

            <b-table-column field="ceft_payment_status" label="CEFT Payment Status" sortable v-slot="props">
                <p class="expanded-col">{{ props.row.ceft_payment_status }}</p>
            </b-table-column>

            <b-table-column field="ceft_payment_description" label="CEFT Payment Description" sortable v-slot="props">
                <p class="expanded-col" style="width: 200px">
                    {{ props.row.ceft_payment_description }}
                </p>
            </b-table-column>

            <b-table-column field="ceft_transaction_id" label="CEFT Transaction ID" sortable v-slot="props">
                <p class="expanded-col">{{ props.row.ceft_transaction_id }}</p>
            </b-table-column>

            <b-table-column field="ceft_try_count" label="CEFT Try Count" sortable v-slot="props">
                <p class="expanded-col">{{ props.row.ceft_try_count }}</p>
            </b-table-column>

            <b-table-column field="ipg_void_payment_time" label="IPG Void Payment Time" sortable v-slot="props">
                <span class="tag is-success expanded-col">{{
                    getDateTimeForDisplaying(props.row.ipg_void_payment_time)
                }}</span>
            </b-table-column>

            <b-table-column field="ipg_void_status" label="IPG Void Status" sortable v-slot="props">
                <p class="expanded-col">{{ props.row.ipg_void_status }}</p>
            </b-table-column>

            <b-table-column field="void_data" label="Void Details" sortable v-slot="props">
                <b-collapse :open="false" aria-id="contentIdForA11y1">
                    <template #trigger="trigger">
                        <b-tooltip label="Click to view">
                            <b-button label="" icon-right="arrow-down-drop-circle-outline" size="is-small"
                                type="is-primary" aria-controls="contentIdForA11y1" :aria-expanded="trigger.open" />
                        </b-tooltip>
                    </template>
                    <div class="notification">
                        <div class="content" style="width: 20rem">
                            <h5>IPG Void Details</h5>
                            <section class="card-content">
                                <div class="content pt-auto">
                                    <b-field label="Void Tran ID" custom-class="is-small">
                                        <b-input :value="props.row.ipg_void_tran_id" size="is-small" readonly></b-input>
                                    </b-field>
                                    <b-field label="Void Description" custom-class="is-small">
                                        <b-input :value="props.row.ipg_void_description" size="is-small"
                                            readonly></b-input>
                                    </b-field>
                                    <b-field label="Void Code" custom-class="is-small">
                                        <b-input :value="props.row.ipg_void_code" size="is-small" readonly></b-input>
                                    </b-field>
                                    <b-field label="Void Message" custom-class="is-small">
                                        <b-input :value="props.row.ipg_void_message" size="is-small" readonly></b-input>
                                    </b-field>
                                </div>
                            </section>
                        </div>
                    </div>
                </b-collapse>
            </b-table-column>

            <b-table-column field="ipg_refund_payment_time" label="IPG Refund Payment Time" sortable v-slot="props">
                <span class="tag is-success expanded-col">{{
                    getDateTimeForDisplaying(props.row.ipg_refund_payment_time)
                }}</span>
            </b-table-column>

            <b-table-column field="ipg_void_status" label="IPG Refund Status" sortable v-slot="props">
                <p class="expanded-col">{{ props.row.ipg_refund_status }}</p>
            </b-table-column>

            <b-table-column field="void_data" label="Refund Details" sortable v-slot="props">
                <b-collapse :open="false" aria-id="contentIdForA11y1">
                    <template #trigger="trigger">
                        <b-tooltip label="Click to view">
                            <b-button label="" icon-right="arrow-down-drop-circle-outline" size="is-small"
                                type="is-primary" aria-controls="contentIdForA11y1" :aria-expanded="trigger.open" />
                        </b-tooltip>
                    </template>
                    <div class="notification">
                        <div class="content" style="width: 20rem">
                            <h5>IPG Refund Details</h5>
                            <section class="card-content">
                                <div class="content pt-auto">
                                    <b-field label="Refund Tran ID" custom-class="is-small">
                                        <b-input :value="props.row.ipg_refund_tran_id" size="is-small"
                                            readonly></b-input>
                                    </b-field>
                                    <b-field label="Refund Description" custom-class="is-small">
                                        <b-input :value="props.row.ipg_refund_description" size="is-small"
                                            readonly></b-input>
                                    </b-field>
                                    <b-field label="Refund Code" custom-class="is-small">
                                        <b-input :value="props.row.ipg_refund_code" size="is-small" readonly></b-input>
                                    </b-field>
                                    <b-field label="Refund Message" custom-class="is-small">
                                        <b-input :value="props.row.ipg_refund_message" size="is-small"
                                            readonly></b-input>
                                    </b-field>
                                </div>
                            </section>
                        </div>
                    </div>
                </b-collapse>
            </b-table-column>

            <b-table-column field="created_at" label="Created At" sortable centered v-slot="props">
                <span class="tag is-success expanded-col">{{
                    getDateTimeForDisplaying(props.row.created_at)
                }}</span>
            </b-table-column>

            <b-table-column field="updated_at" label="Updated At" sortable centered v-slot="props">
                <span class="tag is-success expanded-col">{{
                    getDateTimeForDisplaying(props.row.updated_at)
                }}</span>
            </b-table-column>

            <b-table-column field="action" label="Action" v-slot="props" sticky>
                <div class="columns">
                    <div v-if="canHold(props.row)" class="column">
                        <b-tooltip label="Hold Fund-transfer" position="is-left" type="is-dark"
                            style="margin-left: 10px">
                            <b-button type="is-warning" icon-left="timer-stop" size="is-small"
                                @click="StartTransferHoldAction(props.row)"></b-button>
                        </b-tooltip>
                    </div>
                    <div v-if="canFundTransfer(props.row)" class="column">
                        <b-tooltip label="Manual Fund transfer" position="is-left" type="is-dark"
                            style="margin-left: 10px">
                            <b-button type="is-info" icon-left="autorenew" size="is-small"
                                @click="StartFundTransferAction(props.row)"></b-button>
                        </b-tooltip>
                    </div>
                    <div v-if="canRefund(props.row)" class="column">
                        <div class="b-tooltips" style="width: 150px">
                            <b-tooltip v-if="isToday(props.row.card_payment_time)" label="Void Transaction"
                                position="is-left" type="is-dark" style="margin-left: 10px">
                                <b-button type="is-danger" size="is-small" label="Void"
                                    @click="StartRefundAction('void', props.row.id)"></b-button>
                            </b-tooltip>
                            <b-tooltip v-else label="Refund Transaction" position="is-left" type="is-dark"
                                style="margin-left: 10px">
                                <b-button type="is-danger" size="is-small" label="Refund"
                                    @click="StartRefundAction('refund', props.row.id)"></b-button>
                            </b-tooltip>
                        </div>
                    </div>
                </div>
            </b-table-column>
        </b-table>
    </div>
</template>
<script>
import NetworkManager from "@/network";
import { appSettings } from "@/appSettings";
import { TransactionExportWizard } from "@/views/Reports/Supports";
// import * as XLSX from 'xlsx';

export default {
    name: "CEFTTransactionsUSD",
    components: {},
    data() {
        return {
            filterChanged: false,
            fromDate: new Date(),
            toDate: new Date(),
            status: "",
            countryCode: "",
            downlodStatus: "",
            data: null,
            countryList: [],
            fetchCount: 20,
            transactions: [],
            filteredList: [],

            exportWizard: new TransactionExportWizard("CEFT Transactions-USD"),

            tableOptions: {
                isPaginated: true,
                isPaginationSimple: false,
                isPaginationRounded: false,
                paginationPosition: "bottom",
                defaultSortDirection: "asc",
                sortIcon: "arrow-up",
                sortIconSize: "is-small",
                currentPage: 1,
                perPage: 50,
                hasInput: false,
                paginationOrder: "",
                inputPosition: "",
                inputDebounce: "",
                stickyHeaders: true,

            },

            tableHeaders: [],
        };
    },

    computed: {},

    watch: {
        fromDate() {
            this.filterChanged = true;
        },

        toDate() {
            this.filterChanged = true;
        },
    },

    methods: {
        generateXlsx: function () {
            const self = this;

            NetworkManager.apiRequest(
                "api/Transaction/save-downloded-cefts",
                {},
                function (response) {
                    if (response.statusCode === 200) {
                        self.$buefy.toast.open({
                            message: "Download successfully",
                            type: "is-success",
                            position: "is-top",
                        });

                        self.exportWizard.exportToXlsx(
                            new Date(),
                            self.authUser().username,
                            response.data.fromDate,
                            response.data.toDate,
                            "ceftTransactionsUSD_" + self.formatDateTime(new Date()),
                            response.data.fields,
                            response.data.filteredList
                        );
                        location.reload()
                    } else if (response.statusCode === 422) {
                        self.$buefy.toast.open({
                            message: "No Transactions to download",
                            type: "is-warning",
                            position: "is-top",
                        });
                        location.reload()
                    } else {
                        self.$buefy.toast.open({
                            message: "Failed to download",
                            type: "is-danger",
                            position: "is-top",
                        });
                    }
                }
            );
        },

        generatePdf: function () {
            const self = this;

            NetworkManager.apiRequest(
                "api/Transaction/save-downloded-cefts",
                {},
                function (response) {
                    if (response.statusCode === 200) {
                        self.$buefy.toast.open({
                            message: "Download successfully",
                            type: "is-success",
                            position: "is-top",
                        });

                        self.exportWizard.exportToXlsx(
                            new Date(),
                            self.authUser().username,
                            response.data.fromDate,
                            response.data.toDate,
                            "ceftTransactionsUSD_" + self.formatDateTime(new Date()),
                            response.data.fields,
                            response.data.filteredList
                        );
                        location.reload()
                    } else if (response.statusCode === 422) {
                        self.$buefy.toast.open({
                            message: "No Transactions to download",
                            type: "is-warning",
                            position: "is-top",
                        });
                        location.reload()
                    } else {
                        self.$buefy.toast.open({
                            message: "Failed to download",
                            type: "is-danger",
                            position: "is-top",
                        });
                    }
                }
            );
        },
        isImage(path) {
            const imageExtensions = ["jpg", "jpeg", "png"];
            const fileExtension = path.split(".").pop().toLowerCase();
            return imageExtensions.includes(fileExtension);
        },
        getFileName(path) {
            return path.split("/").pop();
        },
        getPdfUrl(path) {
            return appSettings.$api_url + "/" + path;
        },
        getImageUrl: function (path) {
            return appSettings.$api_url + "/" + path + "?" + new Date().getTime();
        },

        onFromDateChange: function () {
            const self = this;

            if (self.toDate.getTime() < self.fromDate.getTime()) {
                self.toDate = self.fromDate;
            }
        },


        canRefund: function (tran) {
            return (
                tran.ipg_void_status !== "SUCCESS" &&
                tran.ipg_refund_status !== "SUCCESS" &&
                tran.refund_requested &&
                this.hasPermission("transaction-refund")
            );
        },

        canFundTransfer: function (tran) {
            return (
                tran.ipg_void_status !== "SUCCESS" &&
                tran.ipg_refund_status !== "SUCCESS" &&
                tran.ceft_payment_status !== "SUCCESS" &&
                tran.card_payment_status === "SUCCESS" &&
                this.hasPermission("transaction-retry")
            );
        },

        canHold: function (tran) {
            return (
                tran.ipg_void_status !== "SUCCESS" &&
                tran.ipg_refund_status !== "SUCCESS" &&
                tran.ceft_payment_status !== "SUCCESS" &&
                tran.card_payment_status === "SUCCESS" &&
                this.hasPermission("transaction-hold") &&
                tran.ceft_try_count < 100
            );
        },

        isToday: function (dateStr) {
            let today = this.formatDate(new Date().toLocaleString());
            let date = this.formatDate(dateStr);

            return today === date;
        },

        loadData: function () {
            const self = this;

            self.fromDate.setSeconds(1);
            self.toDate.setSeconds(0);

          
            self.showFullScreenLoader();

            NetworkManager.apiRequest(
                "api/Transaction/get-Pending-To-Download",
                {},
                function (e) {
                    if (e.statusCode === 200) {
                        self.transactions = e.data.transactions;
                        self.filteredList = e.data.filteredList;
                        self.tableHeaders = e.data.fields;
                        self.countryList = e.data.countries;
                        self.filterChanged = false;
                        
                    }

                    self.hideFullScreenLoader();
                }
            );
        },

        StartTransferHoldAction: function (tran) {
            const self = this;

            if (self.hasRatesUpdated) {
                self.GenerateReport();
            } else {
                self.$buefy.dialog.confirm({
                    type: "is-danger",
                    title: "Warning!",
                    message:
                        "Taking this action will hold the fund-transfer of the customer (Tran ID - " +
                        tran.id +
                        ").<br>" +
                        " Do you want to continue?",
                    onConfirm: () => self.holdFundTransfer(tran.id, ""),
                });
            }
        },

        StartRefundAction: function (type, Id) {
            const self = this;

            if (self.hasRatesUpdated) {
                self.GenerateReport();
            } else {
                self.$buefy.dialog.confirm({
                    type: "is-danger",
                    title: "Warning!",
                    message:
                        "Taking this action will refund the card payment of the customer (Tran ID - " +
                        Id +
                        ").<br>" +
                        " Do you want to continue?",
                    onConfirm: () =>
                        type === "void"
                            ? self.voidTransaction(Id)
                            : self.refundTransaction(Id),
                });
            }
        },

        StartFundTransferAction: function (tran) {
            const self = this;

            if (self.hasRatesUpdated) {
                self.GenerateReport();
            } else {
                self.$buefy.dialog.confirm({
                    type: "is-danger",
                    title: "Warning!",
                    message:
                        "Taking this action will start a manual fund transferring for this transaction (Tran ID - " +
                        tran.id +
                        "). <br>" +
                        "If you not sure funds are availabe, please do not take this action. <br>" +
                        "Are you sure to take this action?",
                    onConfirm: () => self.manualFundTransfer(tran),
                });
            }
        },

        voidTransaction: function (Id) {
            const self = this;

            let data = {
                transactionID: Id,
            };

            self.showFullScreenLoader();

            NetworkManager.apiRequest(
                "api/Transaction/ceft/void",
                data,
                function (e) {
                    if (e.statusCode === 200) {
                        const params = {
                            type: "is-success",
                            message: "Transaction voided successfully: Id - " + Id,
                        };
                        self.$buefy.toast.open(params);
                        self.loadData();
                    } else {
                        const params = {
                            type: "is-warning",
                            message: e.data.message,
                        };
                        self.$buefy.toast.open(params);

                        self.hideFullScreenLoader();
                    }
                }
            );
        },

        refundTransaction: function (Id) {
            const self = this;

            let data = {
                transactionID: Id,
            };

            self.showFullScreenLoader();

            NetworkManager.apiRequest(
                "api/Transaction/ceft/refund",
                data,
                function (e) {
                    if (e.statusCode === 200) {
                        const params = {
                            type: "is-success",
                            message: "Transaction refunded successfully: Id - " + Id,
                        };
                        self.$buefy.toast.open(params);
                        self.loadData();
                    } else {
                        const params = {
                            type: "is-warning",
                            message: e.data.message,
                        };
                        self.$buefy.toast.open(params);

                        self.hideFullScreenLoader();
                    }
                }
            );
        },

        manualFundTransfer: function (tran) {
            const self = this;

            let data = {
                trace: tran.trace,
            };

            self.showFullScreenLoader();

            NetworkManager.apiRequest(
                "api/Transaction/ceft/manualFundTransfer",
                data,
                function (e) {
                    if (e.statusCode === 200) {
                        const params = {
                            type: "is-success",
                            message: "Fund transfer successful: Id - " + tran.id,
                        };
                        self.$buefy.toast.open(params);
                        self.loadData();
                    } else {
                        const params = {
                            type: "is-warning",
                            message: e.data.message,
                        };
                        self.$buefy.toast.open(params);

                        self.hideFullScreenLoader();
                    }
                }
            );
        },

        holdFundTransfer: function (id, desc) {
            const self = this;

            let data = {
                id: id,
                description: desc,
            };

            self.showFullScreenLoader();

            NetworkManager.apiRequest(
                "api/Transaction/ceft/hold",
                data,
                function (e) {
                    if (e.statusCode === 200) {
                        const params = {
                            type: "is-success",
                            message: "Transaction hold successful: Id - " + id,
                        };
                        self.$buefy.toast.open(params);
                        self.loadData();
                    } else {
                        const params = {
                            type: "is-warning",
                            message: e.data.message,
                        };
                        self.$buefy.toast.open(params);

                        self.hideFullScreenLoader();
                    }
                }
            );
        },
    },

    mounted() {
        let fromDate = new Date();
        fromDate.setHours(0);
        fromDate.setMinutes(0);
        fromDate.setSeconds(0);

        let toDate = new Date();
        toDate.setHours(23);
        toDate.setMinutes(59);
        toDate.setSeconds(59);

        this.fromDate = fromDate;
        this.toDate = toDate;

        this.loadData();
    },
};
</script>

<style scoped></style>
