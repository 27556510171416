<template>
  <div class="card" style="margin: 10px;">
    <div class="card-content">
      <vue-apex-charts
        ref="pieChart"
        type="pie"
        height="300"
        :options="chartOptions"
        :series="chartSeries"
      ></vue-apex-charts>
    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import NetworkManager from "@/network";
const today = new Date();

export default {
  name: "AppUsersChart",
  components: { VueApexCharts },
  data() {
    return {
      dateRange: [today, today],
      selectedFilter: { value: 7, text: "Last Year" },
      filters: [
        { value: 7, text: "This Week" },
        { value: 14, text: "Last Two Weeks" },
        { value: 30, text: "Last Month" },
        { value: 90, text: "Last Three Months" },
        { value: 365, text: "Last Year" },
        { value: 0, text: "Custom" },
      ],

      chartSeries: [],
      chartOptions: {
        chart: {
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false, // hide the toolbar
          },
        },

        labels: [],

        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
          width: 2,
        },
        title: {
          text: "App Users",
        },
        grid: {
          row: {
            colors: ["#00534E", "#00534E"],
            opacity: 0.5,
          },
        },
        xaxis: {
          categories: [],
          title: {
            text: "Months",
          },
        },
        yaxis: {
          title: {
            text: "Number of Allowances",
          },
        },
        legend: {
          position: "right",
        },
      },
    };
  },

  methods: {
    loadData: function () {
      const self = this;

      NetworkManager.apiRequest(
        "api/dashboard/getAppUsersByCountry",
        {},
        function (e) {
          if (e.statusCode === 200) {
            self.chartSeries = e.data.counts;
            self.$refs.pieChart.updateOptions({
              labels: e.data.countries,
              
            });
          } else {
            //
          }
        }
      );
    },
  },

  mounted() {
    this.loadData();
  },
};
</script>

<style scoped></style>
