<template>
  <div>
    <div class="columns" style="margin-top: 10px; margin-bottom: 0;">
      <b-field class="column is-one-fifths is-small" label="From" label-position="on-border">
        <b-datetimepicker v-model="fromDate" @input="onFromDateChange()" size="is-small" :show-week-number="false"
          locale="en-CA" placeholder="Click to select..." icon="calendar-today" trap-focus>
        </b-datetimepicker>
      </b-field>
      <b-field class="column is-one-fifths is-small" label="To" label-position="on-border">
        <b-datetimepicker v-model="toDate" @input="filterChanged = true" size="is-small" :min-date="fromDate"
          :show-week-number="false" locale="en-CA" placeholder="Click to select..." icon="calendar-today" trap-focus>
        </b-datetimepicker>
      </b-field>
      <b-field class="column is-one-fifths is-small" label="Downloaded By"
        label-position="on-border">
        <b-input v-model="Downloaded_by" size="is-small" expanded></b-input>
      </b-field>

      <b-field class="column is-one-fifths is-small" label="Fetch Count" label-position="on-border">
        <b-select v-model="fetchCount" placeholder="Select a Count" size="is-small" expanded>
          <option :value="20" selected>20</option>
          <option :value="50">50</option>
          <option :value="100">100</option>
          <option :value="99999999">All</option>
        </b-select>
      </b-field>
      <b-field class="column is-one-fifths is-small">
        <b-button size="is-small" type="is-success" @click="loadData">Search</b-button>
      </b-field>

    </div>


    <b-table :height="vhToPixels(80)" :data="filteredList" :paginated="tableOptions.isPaginated"
      :per-page="tableOptions.perPage" :current-page.sync="tableOptions.currentPage"
      :pagination-simple="tableOptions.isPaginationSimple" :pagination-position="tableOptions.paginationPosition"
      :default-sort-direction="tableOptions.defaultSortDirection" :pagination-rounded="tableOptions.isPaginationRounded"
      :sort-icon="tableOptions.sortIcon" :sort-icon-size="tableOptions.sortIconSize" default-sort="id"
      aria-next-label="Next page" aria-previous-label="Previous page" aria-page-label="Page"
      aria-current-label="Current page" :sticky-header="tableOptions.stickyHeaders" :page-input="tableOptions.hasInput"
      :pagination-order="tableOptions.paginationOrder" :page-input-position="tableOptions.inputPosition"
      :debounce-page-input="tableOptions.inputDebounce">

      <b-table-column field="id" label="ID" width="40" sortable numeric v-slot="props" sticky>
        {{ props.row.id }}
      </b-table-column>

      <b-table-column field="downloaded_pdf" label="File Name" sortable v-slot="props" centered>
        {{ props.row.file_name.slice("Images/Ceft/DownloadedReports/".length) }}
      </b-table-column>

      <b-table-column field="created_at" label="Download Time" sortable v-slot="props">
        <span class="tag is-success expanded-col">{{ getDateTimeForDisplaying(props.row.downloaded_time) }}</span>
      </b-table-column>

      <b-table-column field="Downloaded_By" label="Downloaded By" sortable v-slot="props">
        <p class="expanded-col">{{ props.row.downloaded_by }}</p>
      </b-table-column>

      <b-table-column field="Redownload" label="Redownload" centered v-slot="props">
        <b-tooltip label="Download as Excel" >
          <b-button size="is-small" type="is-info" icon-right="microsoft-excel" :disabled="props.row.redownload"
            @click="redownloadExcel(props.row.id)"></b-button>
        </b-tooltip>
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
import NetworkManager from "@/network"
import { TransactionExportWizard } from "@/views/Reports/Supports";

export default {
  name: 'CEFTTransactionsUSD',
  components: {
  },
  data() {
    return {
      filteredList: [],
      fromDate: new Date(),
      toDate: new Date(),
      Downloaded_by: "",
      url: "",
      fetchCount: 20,
      exportWizard: new TransactionExportWizard("CEFT Transactions-USD"),
      tableOptions: {
        isPaginated: true,
        countryCode: "",
        isPaginationSimple: false,
        isPaginationRounded: false,
        paginationPosition: 'bottom',
        defaultSortDirection: 'asc',
        sortIcon: 'arrow-up',
        sortIconSize: 'is-small',
        currentPage: 1,
        perPage: 50,
        hasInput: false,
        paginationOrder: '',
        inputPosition: '',
        inputDebounce: '',
        stickyHeaders: true,
      },

    }
  },

  methods: {
    redownloadExcel(id) {
      const self = this;

      NetworkManager.apiRequest('api/Transaction/redownloded-cefts', { id }, function (e) {
        if (e.statusCode === 200) {
          self.data = e.data;
          self.url = e.data.file_name;
          console.log(self.url);
          self.openNewTab(self.url);
          console.log(e.data);

          
          self.$buefy.toast.open({
            message: 'Redownload successfully',
            type: 'is-success',
            position: 'is-top'
          });

        }
        else {
          self.$buefy.toast.open({
            message: 'Failed to download',
            type: 'is-danger',
            position: 'is-top'
          });
        }
      });

    },

    openNewTab(url) {
      window.open(url, '_blank');
    },

    loadData: function () {
      const self = this

      self.showFullScreenLoader()

      let data = {
        from_date: self.formatDateTime(self.fromDate),
        to_date: self.formatDateTime(self.toDate),
        Downloaded_by: self.Downloaded_by ? self.Downloaded_by.toLocaleLowerCase() : null,
        fetchCount: self.fetchCount,
      };

      NetworkManager.apiRequest('api/Transaction/get-downloded-cefts', data , function (e) {
        if (e.statusCode === 200) {
          self.filteredList = e.data.filteredList
          
        }
        console.log(self.ceftExcelData)

        self.hideFullScreenLoader()
      })
    },
   
  },
  mounted() {
    let fromDate = new Date();
    fromDate.setHours(0);
    fromDate.setMinutes(0);
    fromDate.setSeconds(0);

    let toDate = new Date();
    toDate.setHours(23);
    toDate.setMinutes(59);
    toDate.setSeconds(59);

    this.fromDate = fromDate;
    this.toDate = toDate;

    this.loadData();
  },
}
</script>



