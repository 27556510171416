<template>
  <section>
    <div class="card-header p-3">
      <div class="columns" style="width: 100%">
        <div class="column">
          <b-breadcrumb
              align="is-left"
          >
            <b-breadcrumb-item tag='router-link' to="/newsfeed" >News Feed</b-breadcrumb-item>
            <b-breadcrumb-item tag='router-link' to="" active >Create</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
        <div class="column">
        </div>
      </div>
    </div>

    <div class="card-content" style="overflow: auto; height: 65vh">
      <section>
        <b-field
            horizontal
            :type="newsFeedData.title.class"
            :message="newsFeedData.title.message">
          <template slot="label">Title <span class="has-text-danger">*</span></template>
          <b-input @blur="newsFeedData.title.blur()" v-model="newsFeedData.title.value" maxlength="250" expanded></b-input>
        </b-field>
        <!-- <b-field
            horizontal
            :type="newsFeedData.valid_from.class"
            :message="newsFeedData.valid_from.message">
          <template slot="label">Valid From <span class="has-text-danger">*</span></template>
          <b-datepicker
              v-model="newsFeedData.valid_from.value"
              @input="onFromDateChange()"
              :show-week-number="false"
              :min-date="new Date()"
              locale="en-CA"
              placeholder="Click to select..."
              icon="calendar-today"
              trap-focus>
          </b-datepicker>
        </b-field>

        <b-field
            horizontal
            :type="newsFeedData.valid_to.class"
            :message="newsFeedData.valid_to.message">
          <template slot="label">Valid To <span class="has-text-danger">*</span></template>
          <b-datepicker
              v-model="newsFeedData.valid_to.value"
              :min-date="newsFeedData.valid_from.value"
              :show-week-number="false"
              locale="en-CA"
              placeholder="Click to select..."
              icon="calendar-today"
              trap-focus>
          </b-datepicker>
        </b-field> -->

        <b-field
            horizontal
            :type="newsFeedData.content.class" 
            :message="newsFeedData.content.message">
          <template slot="label">Content <span class="has-text-danger">*</span></template>
          <b-input @blur="newsFeedData.content.blur()" v-model="newsFeedData.content.value" maxlength="2500" type="textarea" expanded></b-input>
        </b-field>

      
        <b-field horizontal
                 :type="newsFeedData.image.class"
                 :message="newsFeedData.image.message" >
          <template slot="label">Image <span class="has-text-danger">*</span></template>
          <div class="">
            <div class="columns is-align-content-space-evenly is-flex-wrap-wrap" >
              <div class="column">
                <image-upload-component
                :type="newsFeedData.image.class"
                v-model="newsFeedData.image.value"
                :index="0"
                :image-height="imageHeight"
                :image-width="imageWidth"
                :image-validations="imageValidations"
                :placeholder_image="placeholder_image"
                @on-upload="onImageUpload"
            ></image-upload-component>
              </div>
              <div class="column" style="text-align: center; height: 200px; width: 200px">
              </div>
            </div>
          </div>
        </b-field>

        <b-field
            horizontal
            message=" ">
          <template slot="label">Status <span class="has-text-danger">*</span></template>
          <div class="block">
            <b-radio v-model="newsFeedData.disabled.value"

                     name="status"
                     native-value="0">
              Active
            </b-radio>
            <b-radio v-model="newsFeedData.disabled.value"
                     name="status"
                     native-value="1"
                     type="is-danger">
                     Not Active
            </b-radio>

          </div>
        </b-field>

      </section>
    </div>

    <footer class="card-footer">
      <div class="columns" style="width: 100%">
        <div class="column">
          <p class="column mb-0 pb-1 has-text-danger is-italic">Fields marked with <big>*</big> are mandatory</p>
          <p class="column mt-0 pt-0 mb-0 pb-0 has-text-danger is-italic">Upload Image Size:</p>
          <p class="column mt-0 pt-0 has-text-danger is-italic">Width:800-900px , Height:400-450px</p>   
        </div>
        <div class="column">
          <div class="buttons is-pulled-right" style="margin: 5px">
            <b-button @click="validateAndSubmit()" :loading="false" type="is-success">Save</b-button>
            <b-button @click="$router.go(-1)"  type="is-danger">Cancel</b-button>
          </div>
        </div>
      </div>
    </footer>
  </section>
</template>

<script>
import NetworkManager from "@/network"
import { FieldValidator } from "@/helpers/validators/fieldValidator"
import ImageUploadComponent, {ImageValidator} from "@/components/ImageUploadComponent"

export default {
  name: "CreateNewsFeed",
  components: {ImageUploadComponent},
  data() {
    return {

      imageHeight: 200,
      imageWidth : 300,
      marginError: 15,
      image: null,
      imageValidations: new ImageValidator(450, 400, 900, 800, "Uploaded image is not valid"),
      placeholder_image: require('@/assets/image-placeholder.png'),

      newsFeedData: {
        title: new FieldValidator(/^(.*[a-zA-Z]){2,}[\w\s\S]*$/),
        content: new FieldValidator(/^(.*[a-zA-Z]){2,}[\w\s\S]*$/),
        valid_from: new FieldValidator(undefined, undefined, new Date(), true),
        valid_to: new FieldValidator(undefined, undefined, new Date(), true),
        image: new FieldValidator(/[\w/\\]*/,
            "Please upload an image"),
        disabled: new FieldValidator()
      },

      checkBoxVal: false
    }
  },


  watch: {
  },

  methods: {

    onImageUpload: function (data){
      this.newsFeedData.image.blur()
      if(!data.valid){
        this.newsFeedData.image.invalidate('Uploaded image is invalid')
      }
    },
    validate: function (){
      const self = this
      let isValid = true

      // Check if the image field is empty
      if (!self.newsFeedData.image.value) {
        self.newsFeedData.image.invalidate('Please upload a image');
        isValid = false;
      }

      Object.keys(self.newsFeedData).forEach(function(key) {
        console.log(self.newsFeedData[key].validate())
         if(!self.newsFeedData[key].validate()) isValid = false
      })

      return isValid
    },
    onFromDateChange: function (){
      const self = this

      if(self.newsFeedData.valid_to.value.getTime() < self.newsFeedData.valid_from.value.getTime()){
        self.newsFeedData.valid_to.value = self.newsFeedData.valid_from.value
      }
    },
    validateAndSubmit: function (){
      const self = this
      let validFrom = self.formatDate(self.newsFeedData.valid_from.value)
        let validTo = self.formatDate(self.newsFeedData.valid_to.value)
        validFrom = validFrom.split(' ')[0] + 'T00:00:01'
        validTo = validTo.split(' ')[0] + 'T23:59:59'

        if(self.validate()){
          const data = {
          title : self.newsFeedData.title.value,
          content : self.newsFeedData.content.value,
          valid_from : validFrom,
          valid_to : validTo,
          image : self.newsFeedData.image.value,
          disabled : self.newsFeedData.disabled.value
        }

        self.showFullScreenLoader()

        NetworkManager.apiRequest('api/NewsFeed/create', data, function (e){
          if(e.statusCode === 200){
            const params = {
              type: 'is-success',
              message: 'News Feed sent for approval'
            }
            self.$buefy.toast.open(params)
            self.navigateTo('NewsFeed')
          }
          else {
            const params = {
              type: 'is-warning',
              message: 'News Feed save failed!.'
            }
            self.$buefy.toast.open(params)
          }
          self.hideFullScreenLoader()
        })
        }
      
    }
  },

  mounted() {
    this.newsFeedData.disabled.value = 0
  }
}
</script>

<style scoped>
  .product-image-input {
    display: inline-block;
    position: relative;
    width: 100%;
    height: auto;
    overflow: hidden;
    border: 1px solid #dbdbdb;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 0.5rem;
    margin-bottom: 0.35rem;
  }

  label.product-image-preview {
    background-size: cover;
    background-position: center;
    top: 0;
    left: 0;
    cursor: pointer;
    display: block;
    width: 100%;
  }

  .product-image-edit-or-delete-bg {
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    background-color: #f9f9f97d;
    align-items: center;
    justify-content: center;
  }

  .product-image-input:hover .product-image-edit-or-delete-bg {
    display: flex;
  }

  .product-image-edit-or-delete {
    display: flex;
    flex-direction: row;
  }

  .form-preview .label {
    color: #bd9ea5;
  }

  img#image-to-be-cropped {
    max-height: calc(100vh - 150px);
  }
</style>
