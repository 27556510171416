<template>
    <section>
      <div class="card-header p-3">
        <div class="columns" style="width: 100%">
          <div class="column">
            <b-breadcrumb
                align="is-left"
            >
              <b-breadcrumb-item tag='router-link' to="/notifications" >Notification</b-breadcrumb-item>
              <b-breadcrumb-item tag='router-link' to="" active >Create</b-breadcrumb-item>
            </b-breadcrumb>
          </div>
          <div class="column">
          </div>
        </div>
      </div>
  
      <div class="card-content" style="overflow: auto; height: 50vh">
        <section>
          <b-field
            horizontal
            :type="NotificationData.title.class"
            :message="NotificationData.title.message">
          <template slot="label">Title <span class="has-text-danger">*</span></template>
          <b-input @blur="NotificationData.title.blur()" v-model="NotificationData.title.value" maxlength="100" expanded></b-input>
        </b-field>
  
          <b-field
              horizontal
              :type="NotificationData.message.class"
              :message="NotificationData.message.message">
            <template slot="label">Message <span class="has-text-danger">*</span></template>
            <b-input @blur="NotificationData.message.blur()" v-model="NotificationData.message.value" type="textarea" maxlength="400" expanded></b-input>
          </b-field>

          <!-- <b-field horizontal
                 :type="NotificationData.image.class"
                 :message="NotificationData.image.message" >
          <template slot="label">image <span class="has-text-danger">*</span></template>
          <div class="">
            <div class="columns is-align-content-space-evenly is-flex-wrap-wrap" >
              <div class="column">
                <image-upload-component
                :type="NotificationData.image.class"
                v-model="NotificationData.image.value"
                :index="0"
                :image-height="imageHeight"
                :image-width="imageWidth"
                :image-validations="imageValidations"
                :placeholder_image="placeholder_image"
                @on-upload="onImageUpload"
            ></image-upload-component>
              </div>
              <div class="column" style="text-align: center; height: 200px; width: 200px">
              </div>
            </div>
          </div>
        </b-field> -->
  
          <!-- <b-field horizontal >
          <template slot="label">Countries <span class="has-text-danger">*</span></template>
          <b-field
              :type="NotificationData.countries.class"
              :message="NotificationData.countries.message">
            <b-select v-model="selectedCountry" placeholder="Currency">
              <option value="" disabled selected>Select Country</option>
              <option v-for="country in countryListRef" :key="country.id" :value="country" >{{country.name}}</option>
            </b-select>
            <p class="control">
              <b-button type="is-primary" label="Add" @click="addCountryTag()" />
            </p>
          </b-field>
          <div>
            <b-tooltip  v-for="item in countryTags" :key="item.id" :label="item.name" style="margin-left: 5px; margin-bottom: 5px">
              <b-tag :type="item.code === 'ALL' ? 'is-danger' : 'is-primary'"
                     size="is-medium"
                     closable
                     aria-close-label="Close tag"
                     @close="deleteCountryTag(item)">
                {{item.code}}
              </b-tag>
            </b-tooltip>
          </div>
        </b-field> -->
  
  
          <!-- <b-field horizontal>
            <div class="block"></div>
          </b-field>

          <b-field
            horizontal
            message=" ">
          <template slot="label">Status <span class="has-text-danger">*</span></template>
          <div class="block">
            <b-radio v-model="NotificationData.disabled.value"

                     name="status"
                     native-value="0">
              Active
            </b-radio>
            <b-radio v-model="NotificationData.disabled.value"
                     name="status"
                     native-value="1"
                     type="is-danger">
              Not Active
            </b-radio>

          </div>
        </b-field> -->
  
        </section>
      </div>
  
      <footer class="card-footer">
        <div class="columns" style="width: 100%">
          <div class="column">
            <p class="column has-text-danger is-italic">Fields marked with <big>*</big> are mandatory</p>
          </div>
          <div class="column">
            <div class="buttons is-pulled-right" style="margin: 5px">
              <b-button @click="validateAndSubmit()" :loading="false" type="is-success">Save</b-button>
              <b-button @click="$router.go(-1)"  type="is-danger">Cancel</b-button>
            </div>
          </div>
        </div>
      </footer>
    </section>
  </template>
  
  <script>
  import NetworkManager from "@/network"
  import {FieldValidator} from "@/helpers/validators/fieldValidator"
  // import ImageUploadComponent, {ImageValidator} from "@/components/ImageUploadComponent"

  
  export default {
    name: "NotificationCreate",
  // components: {ImageUploadComponent},
    data() {
      return {
        countryList: [
        {
          code: 'ALL',
          name: 'All Countries'
        }
      ],
      selectedCountry: '',
      countryTags: [],

      imageHeight: 200,
      imageWidth : 300,
      marginError: 15,
      image: null,
      // imageValidations: new ImageValidator(0,0,0,0,0),
      placeholder_image: require('@/assets/image-placeholder.png'),
  
        NotificationData: {
        title: new FieldValidator(/^(.*[a-zA-Z]){2,}[\w\s\S]*$/),
        message: new FieldValidator(/^(.*[a-zA-Z]){2,}[\w\s\S]*$/),
        // countries: new FieldValidator(undefined, 'Please select at least one country'),     
        // image : new FieldValidator(/[\w/\\]*/,
        //     "Please upload an image"),
        disabled: new FieldValidator()
      },

  
        checkBoxVal: false
      }
    },
  
    computed: {
    countryListRef(){
      const self = this

      let list = []

      self.countryList.forEach(function (item){
        if(!self.countryTags.includes(item)){
          list.push(item)
        }
      })

      return list
    }
  },
  
    methods: {
    //   onImageUpload: function (data){
    //   this.newsFeedData.image.blur()
    //   if(!data.valid){
    //     this.newsFeedData.image.invalidate('Uploaded image is invalid')
    //   }
    // },

      addCountryTag: function (){
      const self = this

      self.NotificationData.countries.blur()

      if(self.selectedCountry !== ''){
        self.countryTags.push(self.selectedCountry)
      }

      self.selectedCountry = ''
      self.NotificationData.countries.value = ''

      self.countryTags.forEach(function (item, index){
        self.NotificationData.countries.value += (index === 0 ? '' : '|') + item.code
      })
    },

    deleteCountryTag: function (item){
      const self = this

      let index = self.countryTags.indexOf(item)
      self.countryTags.splice(index, 1)

      self.NotificationData.countries.value = ''

      self.countryTags.forEach(function (item, index){
        self.NotificationData.countries.value += (index === 0 ? '' : '|') + item.code
      })
    },

      initialData: function (){
      const self = this
      NetworkManager.apiRequest('api/Notifications/initial-create-data', {}, function (e){
        if(e.statusCode === 200){
          e.data.countries.forEach(function (item){
            self.countryList.push(item)
          })
        }
      })
    },
    validate: function (){
      const self = this
      let isValid = true

      // Check if the image field is empty
      // if (!self.NotificationData.image.value) {
      //   self.NotificationData.image.invalidate('Please upload a image');
      //   isValid = false;
      // }

      Object.keys(self.NotificationData).forEach(function(key) {
        console.log(self.NotificationData[key].validate())
         if(!self.NotificationData[key].validate()) isValid = false
      })

      return isValid
    },

      validateAndSubmit: function (){
        const self = this
  
        if(self.validate()){
          let countries = []

          self.countryTags.forEach(function (item){
          countries.push(item.code)
        })
          const data = {
            title : self.NotificationData.title.value,
            message : self.NotificationData.message.value,
            // image : self.NotificationData.image.value,
            // countries : countries,
            disabled : self.NotificationData.disabled.value
          }
  
          NetworkManager.apiRequest('api/Notifications/create', data, function (e){
            if(e.statusCode === 200){
              const params = {
                type: 'is-success',
                message: 'Notification sent for approval'
              }
              self.$buefy.toast.open(params)
              self.$router.go(-1)
            }
            else if(e.statusCode === 422){
              const params = {
                type: 'is-warning',
                message: e.data.message
              }
              self.$buefy.toast.open(params)
            }
            else {
              const params = {
                type: 'is-warning',
                message: 'Notification save failed!.'
              }
              self.$buefy.toast.open(params)
            }
          })
        }
      }
    },  
    mounted() {
    this.NotificationData.disabled.value = 0
    // this.initialData()
  }
  }
  </script>
  
  <style scoped>
  </style>
  